import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import axios from "axios";
import { Link } from "react-router-dom";

import CommonHelmet from "./Components/CommonHelmet";

import config from "./config";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios.get(config.apiUrl + "allblog.php").then((res) => {
      setBlogs(res.data.blogs);
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const totalItems = blogs.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogs.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => window.scrollTo(0, 0), [currentPage]);

  const DoubleArrowOne = () => {
    setCurrentPage(1);
  };
  const DoubleArrowTwo = () => {
    setCurrentPage(totalPages);
  };
  const SingleArrowOne = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(1);
    }
  };
  const SingleArrowTwo = () => {
    if (currentPage !== blogs.length) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(blogs.length);
    }
  };

  return (
    <>
      <CommonHelmet
        pageTitle="Thailand Tour Packages in Coimbatore|Thailand Tour Packages from Coimbatore"
        pageDescription="Explore Thailand with our exclusive tour packages from Coimbatore. Discover the beauty of Thailand with expertly crafted tours tailored to your preferences.
      
      "
        pageKeywords="Best International Packages from Coimbatore, Best Domestic Package from
      Coimbatore, Bali Package from Coimbatore, Thailand Package from Coimbatore, Maldives Package from
      Coimbatore
      "
      />
      <Header />
      {/* <!--Main blog section page--> */}
      <section className="blog-sec">
        <div className="blog-sec-inn container">
          <div className="row">
            <div className="blog-content-box">
              <div className="blog-head">
                <p className="cursive-heading">Live with wanderlust</p>
                <h1 className="main-heading">Travel Blog</h1>
                <p className="para">
                  We share our experiences, tips and travel stories to inspire
                  and guide our readers in their own wanderlust adventures. From
                  hidden gems to popular destinations, we showcase the beauty
                  and diversity of the world, and promote responsible and
                  sustainable travel.
                </p>
              </div>
            </div>
            <div className="sub-blog-item">
              {/* <?php foreach($blogs as $b) { 
                $dateCreated1 = new DateTime($blog['date_created']);
                $formattedDate1 = $dateCreated1->format('F j, Y');
                $shortContent = substr($b['content'], 0, 400) . '...';
                ?> */}

              {currentItems.map((blog) => (
                <div className="row">
                  <div className="col-md-4">
                    <div className="blog-img">
                      <Link
                        to={`/blog_details/${blog.id}/${blog.name
                          ?.trim()
                          .replace(/\s/g, "-")
                          .toLowerCase()}`}
                      >
                        <img
                          className="img-fluid"
                          src={`uploads/blog/${blog.img}`}
                          alt="blogs"
                          title="blogs"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="blog-body">
                      <h2 className="mini-heading">
                        {blog.name}
                        {/* <?php echo $b['name'] ?> */}
                      </h2>
                        <p
                          className="para"
                          dangerouslySetInnerHTML={{ __html: blog.intro}}
                        ></p>
                      <div className="price-cont">
                        <Link
                          to={`/blog_details/${blog.id}/${blog.name
                            ?.trim()
                            .replace(/\s/g, "-")
                            .toLowerCase()}`}
                        >
                          Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {totalPages > 1 && (
                <>
                  {currentPage === 1 && (
                    <div className="pagesBox">
                      <a className="pagination">
                        <button className="btns">{currentPage}</button>
                      </a>
                      <a className="pagination">
                        <button
                          className="btns-navy"
                          onClick={SingleArrowTwo}
                          disabled={currentPage === totalPages}
                        >
                          &gt;
                        </button>
                      </a>
                      <a className="pagination">
                        <button className="btns-navy" onClick={DoubleArrowTwo}>
                          &gt;&gt;
                        </button>
                      </a>
                    </div>
                  )}
                  {currentPage !== 1 && currentPage !== totalPages && (
                    <div className="pagesBox">
                      <a className="pagination">
                        <button className="btns-navy" onClick={DoubleArrowOne}>
                          {" "}
                          &lt;&lt;
                        </button>
                      </a>
                      <a className="pagination">
                        <button
                          className="btns-navy"
                          onClick={SingleArrowOne}
                          disabled={currentPage === 1}
                        >
                          &lt;
                        </button>
                      </a>
                      <a className="pagination">
                        <button className="btns">{currentPage}</button>
                      </a>
                      <a className="pagination">
                        <button
                          className="btns-navy"
                          onClick={SingleArrowTwo}
                          disabled={currentPage === totalPages}
                        >
                          &gt;
                        </button>
                      </a>
                      <a className="pagination">
                        <button className="btns-navy" onClick={DoubleArrowTwo}>
                          &gt;&gt;
                        </button>
                      </a>
                    </div>
                  )}
                  {currentPage === totalPages && (
                    <div className="pagesBox">
                      <a className="pagination">
                        <button className="btns-navy" onClick={DoubleArrowOne}>
                          &lt;&lt;
                        </button>
                      </a>
                      <a className="pagination">
                        <button className="btns-navy" onClick={SingleArrowOne}>
                          &lt;
                        </button>
                      </a>
                      <a className="pagination">
                        <button className="btns">{totalPages}</button>
                      </a>
                    </div>
                  )}
                </>
              )}

              {/* <?php } ?> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Blog;
