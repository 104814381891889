import Footer from "../common/Footer";
import Header from "../common/Header";
import "../css/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="privacy-policy-container">
          <h1 className="privacy-policy-heading">Privacy Policy</h1>
          <p className="text-muted privacy-para-con">

            At Aspire Holidays, we understand the importance of
            protecting your personal information and are committed to ensuring
            your privacy. This Privacy Policy outlines how we collect, use, and
            protect the information you provide to us.
          </p>


          <h2 className="section-headings">Information We Collect</h2>
          <p className="text-muted privacy-para-con">
            When you make a booking or inquiry on our website, we collect
            personal information such as your name, contact details, billing
            address, and payment information. We also collect information about
            your preferences and travel requirements to tailor our services to
            your needs.
          </p>

          <h2 className="section-headings">How We Use Your Information</h2>
          <p className="text-muted privacy-para-con">
            We use the information we collect to process your bookings,
            communicate with you about your travel arrangements, and provide
            personalized recommendations and offers based on your preferences.
            Your information may also be used for marketing purposes, but only
            with your consent.
          </p>



          <h2 className="section-headings">Data Security</h2>
          <p className="text-muted privacy-para-con">
            We take the security of your personal information seriously and
            employ industry-standard measures to protect it from unauthorized
            access, disclosure, alteration, or destruction.
          </p>

          <h2 className="section-headings">Third-Party Services</h2>
          <p className="text-muted privacy-para-con">
            We may engage third-party service providers to assist us in
            delivering our services, such as payment processors and analytics
            providers. These providers are required to adhere to strict privacy
            standards and are only authorized to use your information as
            necessary to fulfill their services to us.
          </p>

          <h2 className="section-headings">Cookies</h2>
          <p className="text-muted privacy-para-con">
            Our website uses cookies to enhance your browsing experience and
            provide personalized content. You can choose to disable cookies in
            your browser settings, but please note that certain features of the
            site may not function properly as a result.
          </p>

          <p className="text-muted privacy-para-con">
            However, certain third-party service providers, such as payment
            gateways and other payment transaction processors, have their own
            privacy policies in respect to the information we are required to
            provide to them for your purchase-related transactions.
          </p>
          <p className="text-muted privacy-para-con">
            For these providers, we recommend that you read their privacy
            policies so you can understand the manner in which your personal
            information will be handled by these providers.
          </p>
          <p className="text-muted privacy-para-con">
            In particular, remember that certain providers may be located in or
            have facilities that are located a different jurisdiction than
            either you or us. So if you elect to proceed with a transaction that
            involves the services of a third-party service provider, then your
            information may become subject to the laws of the jurisdiction(s) in
            which that service provider or its facilities are located.
          </p>
          <p className="text-muted privacy-para-con">
            Once you leave our website or are redirected to a third-party
            website or application, you are no longer governed by this Privacy
            Policy or our website’s Terms of Service.
          </p>
          {/*links */}

          <h2 className="section-headings">Links</h2>
          <p className="text-muted privacy-para-con">
            When you click on links on our website, they may direct you away
            from our site. We are not responsible for the privacy practices of
            other sites and encourage you to read their privacy statements.{" "}
          </p>
          {/*SECTION 6 */}

          <h2 className="section-headings">Security</h2>
          <p className="text-muted privacy-para-con">
            To protect your personal information, we take reasonable precautions
            and follow industry best practices to make sure it is not
            inappropriately lost, misused, accessed, disclosed, altered or
            destroyed.
          </p>
          {/*SECTION 7 */}

          <h2 className="section-headings">Cookies</h2>
          <p className="text-muted privacy-para-con">
            We use cookies to maintain session of your user. It is not used to
            personally identify you on other websites.
          </p>
          {/*SECTION 8 */}


          <h2 className="section-headings">Updates to Privacy Policy</h2>
          <p className="text-muted privacy-para-con">
            We reserve the right to update this Privacy Policy from time to
            time. Any changes will be posted on this page, and we encourage you
            to review this policy periodically to stay informed about how we are
            protecting your information.
          </p>

          <h2 className="section-headings">Contact Us</h2>
          <p className="text-muted privacy-para-con">

            If you have any questions or concerns about our Privacy Policy or
            the handling of your personal information, please contact us at{" "}
            <a href="mailto:info@aspireholidays.in" className="contact-link">
            info@aspireholidays.in

            </a>{" "}
            or by mail at the address provided below:
          </p>
          <address className="privacy-para-con text-muted">

            [Aspire Holidays], <br />
            [Second Floor, Nagammai Building,], <br />
            [Dr Nanjappa Road, Near Park Gate], <br />
            [Roundana, Park Gate, Ram Nagar,], <br />
            [Coimbatore, Tamil Nadu 641018]


          </address>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
