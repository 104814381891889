import React, { useEffect, useState } from "react";
import "./css/common.css";
import Header from "./common/Header";
import Footer from "./common/Footer";
import axios from "axios";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import config from "./config";
import CommonHelmet from "./Components/CommonHelmet";

const OurPackages = () => {
  const navigate = useNavigate();

  const country = [
    "Thailand",
    "Malaysia",
    "Singapore",
    "Malaysia",
    "Singapore",
    "Bali",
    "Philippines",
    "China",
    "Hong Kong",
    "Japan",
    "Taiwan",
    "Kazakhstan",
    "South Korea",
    "Uzbekistan",
    "Vietnam",
    "Cambodia",
    "Vietnam",
    "Cambodia",
    "Sri Lanka",
    "Azerbaijan",
    "Maldives",
    "Myanmar",
    "Bhutan",
    "Nepal",
    "Georgia",
    "Armenia",
    "Mongolia",
    "Australia",
    "New Zealand",
    "Fiji",
    "Turkey",
    "Israel",
    "Jordan",
    "Oman",
    "Egypt",
    "Qatar",
    "Saudi",
    "Indonesia",
    "United Arab Emirates",
    "Kenya",
    "Morocco",
    "Mauritius",
    "Seychelles",
    "Zimbabwe",
    "Madagascar",
    "Tanzania",
    "South Africa",
    "Alaska",
    "Canada",
    "USA",
    "South America",
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Czech",
    "Denmark",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Greenland",
    "Hungary",
    "Iceland",
    "Ireland",
    "Italy",
    "Netherlands",
    "Norway",
    "Portugal",
    "Romania",
    "Sweden",
    "UK",
    "Spain",
    "Switzerland",
  ];

  const [currentValue, setCurrentValue] = useState(0);
  const [showAmount, setShowAmount] = useState(false);

  const [countryData, setCountryData] = useState([]);
  const [countryData1, setCountryData1] = useState([]);
  const [countryData2, setCountryData2] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedInt, setSelectedInt] = useState("");
  const [selectedSubcat, setSelectedSubcat] = useState("");
  const [catData, setCatData] = useState("");
  const [subCatData, setSubCatData] = useState("");
  const [duration, setDuration] = useState("");
  const [amount, setAmount] = useState("");
  const [amountVal, setAmountVal] = useState("");
  const [search, setSearch] = useState("");

  const [noresult, setNoResult] = useState(false);

  const handleCountryChange = (event) => {
    const newSelectedCountry = event.target.value;
    setSelectedCountry(newSelectedCountry);
    window.location.href = `/package?country=${encodeURIComponent(
      newSelectedCountry
    )}&sub=${selectedSubcat}&cat=${selectedInt}&duration=${duration}&amount=${amount}`;
  };

  const handleCategory = (event) => {
    const newSelectedInt = event.target.value;
    setSelectedInt(newSelectedInt);
    window.location.href = `/package?cat=${encodeURIComponent(newSelectedInt)}`;
  };

  const handleSubCategory = (event) => {
    const newSelectedInt = event.target.value;
    setSelectedSubcat(newSelectedInt);
    window.location.href = `/package?sub=${encodeURIComponent(
      newSelectedInt
    )}&duration=${duration}&cat=${selectedInt}&country=${selectedCountry}`;
  };

  const handleDuration = (event) => {
    const newSelectedInt = event.target.value;
    setDuration(newSelectedInt);
    window.location.href = `/package?duration=${encodeURIComponent(
      newSelectedInt
    )}&sub=${selectedSubcat}&cat=${selectedInt}&country=${selectedCountry}&amount=${amount}`;
  };

  const handleSearch = (event) => {
    const newSelectedInt = event.target.value;
    setSearch(newSelectedInt);
    setTimeout(() => {
      navigate(
        `/package?search=${encodeURIComponent(
          newSelectedInt
          // search
        )}`
      );
      // window.location.href = `/package?search=${encodeURIComponent(
      //   newSelectedInt
      //   // search
      // )}`;
    }, 2000);
  };

  const handleAmount = (event) => {
    event.preventDefault();
    setShowAmount(true);
    const currentValue = event.target.value;
    setCurrentValue(currentValue);

    setTimeout(() => {
      const newSelectedInt = event.target.value;
      setAmount(newSelectedInt);
      window.location.href = `/package?amount=${encodeURIComponent(
        newSelectedInt
      )}&sub=${selectedSubcat}&cat=${selectedInt}&country=${selectedCountry}&duration=${duration}`;
    }, 2000);
  };

  const location = useLocation();
  const [filter, setFilter] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalItems = filter.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const packageRes = await axios.get(config.apiUrl + "allpackage.php");
        let filteredData = packageRes.data.package;


        const res1 = await axios.get(config.apiUrl + "countrystate.php");
        setCountryData(res1.data.data);

        const res2 = await axios.get(config.apiUrl + "countrydata.php");
        setCountryData1(res2.data.data);
        const res3 = await axios.get(config.apiUrl + "statedata.php");
        setCountryData2(res3.data.data);

        const catRes = await axios.get(config.apiUrl + "category.php");
        setCatData(catRes.data.catdata);

        const subRes = await axios.get(config.apiUrl + "subcategory.php");
        setSubCatData(subRes.data.data);

        const searchParams = new URLSearchParams(location.search);
        const country = searchParams.get("country");
        const cat = searchParams.get("cat");
        const sub = searchParams.get("sub");
        const duration = searchParams.get("duration");
        const amount = searchParams.get("amount");
        const state = searchParams.get("state");
        const search = searchParams.get("search");
        const name = searchParams.get("name");
        const world = searchParams.get("world");
        const days = searchParams.get("days");

        if (country) {
          filteredData = filteredData.filter(
            (item) =>
              item.country.replace(/\s/g, "").toLowerCase() === country ||
              item.state.replace(/\s/g, "").toLowerCase() === country
          );
        }
        if (cat) {
          filteredData = filteredData.filter(
            (item) => item.categoryid === parseInt(cat)
          );
        }
        if (sub) {
          filteredData = filteredData.filter(
            (item) => item.subid === parseInt(sub)
          );
        }
        if (duration) {
          filteredData = filteredData.filter(
            (item) => item.tdays <= parseInt(duration)
          );
        }
        if (amount) {
          filteredData = filteredData.filter(
            (item) => item.amount <= parseInt(amount)
          );
        }
        if (state) {
          filteredData = filteredData.filter(
            (item) => item.state.replace(/\s/g, "").toLowerCase() === state
          );
        }
        if (search) {
          const searchLowerCase = search.toLowerCase();
          filteredData = filteredData.filter((item) =>
            item.name.replace(/\s/g, "").toLowerCase().includes(searchLowerCase)
          );
        }

        if (name || world || days) {
          if (name) {
            const searchLowerCase = name.toLowerCase();
            filteredData = filteredData.filter((item) =>
              item.name
                .replace(/\s/g, "")
                .toLowerCase()
                .includes(searchLowerCase)
            );
          }
          if (world) {
            filteredData = filteredData.filter(
              (item) => item.country.replace(/\s/g, "").toLowerCase() === world
            );
          }
          if (days) {
            filteredData = filteredData.filter(
              (item) => item.tdays <= parseInt(days)
            );
          }
        }

        if (filteredData.length === 0) {
          setNoResult(true);
        } else {
          setNoResult(false);
          setFilter(filteredData);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message || error);
      }
    };

    fetchData();
  }, [location.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const country = searchParams.get("country");
    const cat = searchParams.get("cat");
    const sub = searchParams.get("sub");
    const duration = searchParams.get("duration");
    const amount = searchParams.get("amount");
    const state = searchParams.get("state");
    const search = searchParams.get("search");
    const name = searchParams.get("name");
    const world = searchParams.get("world");
    const days = searchParams.get("days");
    if (country) {
      setSelectedCountry(country);
    }
    if (world) {
      setSelectedCountry(world);
    }
    if (name) {
      setSearch(name);
    }
    if (days) {
      setDuration(days);
    }
    if (state) {
      setSelectedCountry(state);
    }
    if (cat) {
      setSelectedInt(cat);
    }
    if (sub) {
      setSelectedSubcat(sub);
    }
    if (duration) {
      setDuration(duration);
    }
    if (amount) {
      setShowAmount(true);
      setCurrentValue(amount);
    }
    if (search) {
      setSearch(search);
    }
  }, [[location.search]]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filter.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => window.scrollTo(0, 0), [currentPage]);

  const DoubleArrowOne = () => {
    setCurrentPage(1);
  };
  const DoubleArrowTwo = () => {
    setCurrentPage(totalPages);
  };
  const SingleArrowOne = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(1);
    }
  };
  const SingleArrowTwo = () => {
    if (currentPage !== filter.length) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(filter.length);
    }
  };

  let msg = `We are excited to share with you our latest tour package, designed to offer an exceptional travel experience`;
  return (
    <>
      <CommonHelmet
pageTitle={`${selectedCountry === "thailand" ? "Thailand Tour Packages from Coimbatore| Thailand Packages From Coimbatore" : selectedCountry === "singapore" ? "Singapore Packages From Coimbatore | Singapore Tour Packages" : selectedCountry === "maldives" ? "Maldives Tour Packages From Coimbatore | Maldives Packages from Coimbatore":selectedCountry === "malaysia" ? "Malaysia Tour Packages From Coimbatore | Malaysia Packages from Coimbatore": selectedCountry === "bali"? "Bali Tour Packages From Coimbatore | Bali Packages from Coimbatore"
: selectedCountry === "philippines"
? "Philippines Tour Packages From Coimbatore | Philippines Packages from Coimbatore"
: selectedCountry === "china"
? "China Tour Packages From Coimbatore | China Packages from Coimbatore"
: selectedCountry === "hongkong"
? "Hong Kong Tour Packages From Coimbatore | Hong Kong Packages from Coimbatore"
: selectedCountry === "japan"
? "Japan Tour Packages From Coimbatore | Japan Packages from Coimbatore"
: selectedCountry === "unitedarabemirates"
? "Dubai Tour Packages From Coimbatore | Dubai Packages from Coimbatore"
: selectedCountry === "indonesia"
? "Bali Tour Packages from Coimbatore| Bali Packages From Coimbatore"
:selectedCountry === "andrapradesh"
    ? "Andhra Pradesh Tour Packages"
    : selectedCountry === "arunachalpradesh"
    ? "Arunachal Pradesh Tour Packages"
    : selectedCountry === "assam"
    ? "Assam Tour Packages"
    : selectedCountry === "bihar"
    ? "Bihar Tour Packages"
    : selectedCountry === "chhattishgarh"
    ? "Chhattisgarh Tour Packages"
    : selectedCountry === "goa"
    ? "Goa Tour Packages"
    : selectedCountry === "gujarat"
    ? "Gujarat Tour Packages"
    : selectedCountry === "haryana"
    ? "Haryana Tour Packages"
    : selectedCountry === "himachalpradesh"
    ? "Himachal Pradesh Tour Packages"
    : selectedCountry === "jharkhand"
    ? "Jharkhand Tour Packages"
    : selectedCountry === "karnataka"
    ? "Karnataka Tour Packages"
    : selectedCountry === "kerala"
    ? "Kerala Tour Packages"
    : selectedCountry === "madhyapradesh"
    ? "Madhya Pradesh Tour Packages"
    : selectedCountry === "maharashtra"
    ? "Maharashtra Tour Packages"
    : selectedCountry === "manipur"
    ? "Manipur Tour Packages"
    : selectedCountry === "meghalaya"
    ? "Meghalaya Tour Packages"
    : selectedCountry === "mizoram"
    ? "Mizoram Tour Packages"
    : selectedCountry === "nagaland"
    ? "Nagaland Tour Packages"
    : selectedCountry === "odisha"
    ? "Odisha Tour Packages"
    : selectedCountry === "punjab"
    ? "Punjab Tour Packages"
    : selectedCountry === "rajasthan"
    ? "Rajasthan Tour Packages"
    : selectedCountry === "sikkim"
    ? "Sikkim Tour Packages"
    : selectedCountry === "tamilnadu"
    ? "Tamil Nadu Tour Packages"
    : selectedCountry === "telangana"
    ? "Telangana Tour Packages"
    : selectedCountry === "tripura"
    ? "Tripura Tour Packages"
    : selectedCountry === "uttarpradesh"
    ? "Uttar Pradesh Tour Packages"
    : selectedCountry === "uttarakhand"
    ? "Uttarakhand Tour Packages"
    : selectedCountry === "westbengal"
    ? "West Bengal Tour Packages"
    : selectedCountry === "andamanandnicobarislands"
    ? "Andaman and Nicobar Islands Packages"
    : selectedCountry === "chandigarh"
    ? "Chandigarh Tour Packages"
    : selectedCountry === "lakshadweep"
    ? "Lakshadweep Tour Packages"
    : selectedCountry === "delhi"
    ? "Delhi Tour Packages"
    : selectedCountry === "pondichery"
    ? "Pondicherry Tour Packages"
    : selectedCountry === "jammuandkashmir"
    ? "Jammu and Kashmir Tour Packages"
    : selectedCountry === "ladakh"
    ? "Ladakh Tour Packages"
    : selectedCountry === "india"
    ? "India Tour Packages"
: selectedCountry === "taiwan"
? "Taiwan Tour Packages From Coimbatore | Taiwan Packages from Coimbatore":""}`}
pageDescription={`${selectedCountry === "thailand" ? "Thailand tour packages from Coimbatore. Discover amazing thailand travel deals and plan your dream vacation today!Tour Packages" : selectedCountry === "singapore" ? "Singapore Tour packages: Discover best offers on Singapore Tour Packages. Plan your Singapore tour and enjoy Singapore vacation packages." 
:selectedCountry === "maldives" ? "Maldives Tour packages from Coimbatore.Explore the beauty of Maldives with our expertly crafted tours. Book Maldives Tour packages at affortable price." 
:selectedCountry === "malaysia" ? "Malaysia Tour packages: Discover best offers on Malaysia Tour Packages. Plan your Malaysia tour and enjoy Malaysia vacation packages."
: selectedCountry === "bali" ?"Bali Tour packages from Coimbatore: Explore the beauty with our Chennai to Bali tour package. Enjoy culture, beaches & more. Book now for an unforgettable Bali experience!"

: selectedCountry === "singapore" 
    ? "Singapore Tour Packages: Experience the dynamic city-state. Book now for an unforgettable journey!"
    : selectedCountry === "philippines"
    ? "Philippines Tour Packages: Discover breathtaking beauty. Book now for your dream vacation!"
    : selectedCountry === "china"
    ? "China Tour Packages: Explore wonders and modern cities. Book now for an unforgettable journey!"
: selectedCountry === "unitedarabemirates"
? "Dubai Tour Packages: Find the Best Offers on Dubai Tour Packages. Plan Your Tour and Get Specific Affordable Dubai Packages."
: selectedCountry === "hongkong"
? "Experience the vibrant culture and stunning attractions of Hong Kong with our tour packages from Coimbatore. Book your dream vacation now!"
: selectedCountry === "japan"

? "Immerse yourself in the beauty and tradition of Japan with our tour packages from Coimbatore. Book your dream vacation to Japan now!"
: selectedCountry === "afghanistan" 
? "Afghanistan Tour Packages: Embark on an adventure to discover rich history and breathtaking landscapes!"
: selectedCountry === "indonesia"
? "Bali Tour packages: Explore the beauty with our Coimbatore to Bali tour package. Enjoy culture, beaches & more. Book now for an unforgettable Bali experience!"

: selectedCountry === "taiwan"
? "Discover the hidden gems of Taiwan with our tour packages from Coimbatore. Book your dream vacation to Taiwan now!"
:selectedCountry === "andrapradesh"
? "Andhra Pradesh Tour Packages: Explore vibrant culture and beautiful landscapes. Book now for an unforgettable journey!"
: selectedCountry === "arunachalpradesh"
? "Arunachal Pradesh Tour Packages: Discover stunning landscapes and cultural heritage. Book now for a memorable experience!"
: selectedCountry === "assam"
? "Assam Tour Packages: Experience natural beauty and rich culture. Book now for a dream vacation!"
: selectedCountry === "bihar"
? "Bihar Tour Packages: Explore historical landmarks and cultural heritage. Book now for an unforgettable journey!"
: selectedCountry === "chhattishgarh"
? "Chhattisgarh Tour Packages: Discover natural beauty and tribal culture. Book now for a memorable experience!"
: selectedCountry === "goa"
? "Goa Tour Packages: Enjoy sun, sand, and sea. Book now for a relaxing beach vacation!"
: selectedCountry === "gujarat"
? "Gujarat Tour Packages: Explore vibrant culture and rich heritage. Book now for an unforgettable journey!"
: selectedCountry === "haryana"
? "Haryana Tour Packages: Discover hidden gems and rich culture. Book now for a memorable experience!"
: selectedCountry === "himachalpradesh"
? "Himachal Pradesh Tour Packages: Experience breathtaking landscapes and adventure. Book now for an unforgettable journey!"
: selectedCountry === "jharkhand"
? "Jharkhand Tour Packages: Discover natural beauty and tribal culture. Book now for a memorable experience!"
: selectedCountry === "karnataka"
? "Karnataka Tour Packages: Explore rich heritage and natural beauty. Book now for an unforgettable journey!"
: selectedCountry === "kerala"
? "Kerala Tour Packages: Experience serene backwaters and lush greenery. Book now for a dream vacation!"
: selectedCountry === "madhyapradesh"
? "Madhya Pradesh Tour Packages: Discover historical landmarks and wildlife sanctuaries. Book now for an unforgettable journey!"
: selectedCountry === "maharashtra"
? "Maharashtra Tour Packages: Explore vibrant culture and stunning landscapes. Book now for a memorable experience!"
: selectedCountry === "manipur"
? "Manipur Tour Packages: Discover natural beauty and rich culture. Book now for an unforgettable journey!"
: selectedCountry === "meghalaya"
? "Meghalaya Tour Packages: Experience pristine landscapes and vibrant culture. Book now for a memorable experience!"
: selectedCountry === "mizoram"
? "Mizoram Tour Packages: Explore hidden gems and cultural heritage. Book now for an unforgettable journey!"
: selectedCountry === "nagaland"
? "Nagaland Tour Packages: Discover tribal culture and natural beauty. Book now for a memorable experience!"
: selectedCountry === "odisha"
? "Odisha Tour Packages: Experience rich heritage and beautiful coastline. Book now for an unforgettable journey!"
: selectedCountry === "punjab"
? "Punjab Tour Packages: Explore vibrant culture and delicious cuisine. Book now for a memorable experience!"
: selectedCountry === "rajasthan"
? "Rajasthan Tour Packages: Discover royal heritage and colorful culture. Book now for an unforgettable journey!"
: selectedCountry === "sikkim"
? "Sikkim Tour Packages: Experience majestic mountains and Buddhist monasteries. Book now for a memorable experience!"
: selectedCountry === "tamilnadu"
? "Tamil Nadu Tour Packages: Explore rich history and stunning temples. Book now for an unforgettable journey!"
: selectedCountry === "telangana"
? "Telangana Tour Packages: Discover historical landmarks and cultural heritage. Book now for a memorable experience!"
: selectedCountry === "tripura"
? "Tripura Tour Packages: Experience natural beauty and cultural heritage. Book now for an unforgettable journey!"
: selectedCountry === "uttarpradesh"
? "Uttar Pradesh Tour Packages: Explore historical landmarks and religious sites. Book now for a memorable experience!"
: selectedCountry === "uttarakhand"
? "Uttarakhand Tour Packages: Discover scenic beauty and adventure. Book now for an unforgettable journey!"
: selectedCountry === "westbengal"
? "West Bengal Tour Packages: Experience cultural diversity and natural beauty. Book now for a memorable experience!"
: selectedCountry === "andamanandnicobarislands"
? "Andaman and Nicobar Islands Tour Packages: Explore pristine beaches and vibrant marine life. Book now for an unforgettable journey!"
: selectedCountry === "chandigarh"
? "Chandigarh Tour Packages: Discover modern architecture and lush greenery. Book now for a memorable experience!"
: selectedCountry === "lakshadweep"
? "Lakshadweep Tour Packages: Experience serene beaches and crystal-clear waters. Book now for an unforgettable journey!"
: selectedCountry === "delhi"
? "Delhi Tour Packages: Explore historical landmarks and vibrant culture. Book now for a memorable experience!"
: selectedCountry === "pondichery"
? "Pondicherry Tour Packages: Discover French colonial charm and tranquil beaches. Book now for an unforgettable journey!"
: selectedCountry === "jammuandkashmir"
? "Jammu and Kashmir Tour Packages: Experience breathtaking landscapes and cultural heritage. Book now for a memorable experience!"
: selectedCountry === "ladakh"
? "Ladakh Tour Packages: Explore majestic mountains and Buddhist monasteries. Book now for an unforgettable journey!"
: selectedCountry === "india"
? "India Tour Packages: Discover the diversity and beauty of India. Book now for an unforgettable journey!"
: ""}`}
        pageKeywords="Top 10 Travel Agencies in Coimbatore, Travel Agents from Coimbatore, Best Travel Agency in Coimbatore, Best International Packages from Coimbatore, Best Domestic Package from Coimbatore, Best America Tour package from coimbatore "
      />

      <Header />

      {/* Banner Section */}
      <section className="cont-banner">
        <div className="banner-box">
          <h1 className="main-heading">
            {/* {selectedSubcat === "2"
              ? "Honeymoon Tour Packages"
              : selectedSubcat === "8"
              ? "Pilgrimage Tour Packages"
              : "OUR PACKAGES"} */}
              
               { selectedCountry === "thailand"
      ? "Thailand Tour Packages"
      : selectedCountry === "malaysia"
      ? "Malaysia Tour Packages"
      : selectedCountry === "afghanistan"
      ? "Afghanistan Tour Packages"
      : selectedCountry === "singapore"
      ? "Singapore Tour Packages"
      : selectedCountry === "bali"
      ? "Bali Tour Packages"
      : selectedCountry === "philippines"
      ? "Philippines Tour Packages"
      : selectedCountry === "china"
      ? "China Tour Packages"
      : selectedCountry === "hongkong"
      ? "Hong Kong Tour Packages"
      : selectedCountry === "japan"
      ? "Japan Tour Packages"
      : selectedCountry === "taiwan"
      ? "Taiwan Tour Packages"
      : selectedCountry === "kazakhstan"
      ? "Kazakhstan Tour Packages"
      : selectedCountry === "southkorea"
      ? "South Korea Tour Packages"
      : selectedCountry === "uzbekistan"
      ? "Uzbekistan Tour Packages"
      : selectedCountry === "vietnam"
      ? "Vietnam Tour Packages"
      : selectedCountry === "cambodia"
      ? "Cambodia Tour Packages"
      : selectedCountry === "srilanka"
      ? "Sri Lanka Tour Packages"
      : selectedCountry === "azerbaijan"
      ? "Azerbaijan Tour Packages"
      : selectedCountry === "maldives"
      ? "Maldives Tour Packages"
      : selectedCountry === "myanmar"
      ? "Myanmar Tour Packages"
      : selectedCountry === "bhutan"
      ? "Bhutan Tour Packages"
      : selectedCountry === "nepal"
      ? "Nepal Tour Packages"
      : selectedCountry === "georgia"
      ? "Georgia Tour Packages"
      : selectedCountry === "armenia"
      ? "Armenia Tour Packages"
      : selectedCountry === "mongolia"
      ? "Mongolia Tour Packages"
      : selectedCountry === "australia"
      ? "Australia Tour Packages"
      : selectedCountry === "newzealand"
      ? "New Zealand Tour Packages"
      : selectedCountry === "fiji"
      ? "Fiji Tour Packages"
      : selectedCountry === "turkey"
      ? "Turkey Tour Packages"
      : selectedCountry === "israel"
      ? "Israel Tour Packages"
      : selectedCountry === "jordan"
      ? "Jordan Tour Packages"
      : selectedCountry === "oman"
      ? "Oman Tour Packages"
      : selectedCountry === "egypt"
      ? "Egypt Tour Packages"
      : selectedCountry === "qatar"
      ? "Qatar Tour Packages"
      : selectedCountry === "saudi"
      ? "Saudi Tour Packages"
      : selectedCountry === "indonesia"
      ? "Bali Tour Packages"
      : selectedCountry === "unitedarabemirates"
      ? "Dubai Tour Packages"
      : selectedCountry === "kenya"
      ? "Kenya Tour Packages"
      : selectedCountry === "morocco"
      ? "Morocco Tour Packages"
      : selectedCountry === "mauritius"
      ? "Mauritius Tour Packages"
      : selectedCountry === "seychelles"
      ? "Seychelles Tour Packages"
      : selectedCountry === "zimbabwe"
      ? "Zimbabwe Tour Packages"
      : selectedCountry === "madagascar"
      ? "Madagascar Tour Packages"
      : selectedCountry === "tanzania"
      ? "Tanzania Tour Packages"
      : selectedCountry === "southafrica"
      ? "South Africa Tour Packages"
      : selectedCountry === "alaska"
      ? "Alaska Tour Packages"
      : selectedCountry === "canada"
      ? "Canada Tour Packages"
      : selectedCountry === "usa"
      ? "USA Tour Packages"
      : selectedCountry === "southamerica"
      ? "South America Tour Packages"
      : selectedCountry === "austria"
      ? "Austria Tour Packages"
      : selectedCountry === "belgium"
      ? "Belgium Tour Packages"
      : selectedCountry === "bulgaria"
      ? "Bulgaria Tour Packages"
      : selectedCountry === "croatia"
      ? "Croatia Tour Packages"
      : selectedCountry === "czech"
      ? "Czech Tour Packages"
      : selectedCountry === "denmark"
      ? "Denmark Tour Packages"
      : selectedCountry === "finland"
      ? "Finland Tour Packages"
      : selectedCountry === "france"
      ? "France Tour Packages"
      : selectedCountry === "germany"
      ? "Germany Tour Packages"
      : selectedCountry === "greece"
      ? "Greece Tour Packages"
      : selectedCountry === "greenland"
      ? "Greenland Tour Packages"
      : selectedCountry === "hungary" ?"hungary Tour Packages": selectedCountry === "iceland"
      ? "Iceland Tour Packages"
      : selectedCountry === "ireland"
      ? "Ireland Tour Packages"
      : selectedCountry === "italy"
      ? "Italy Tour Packages"
      : selectedCountry === "netherlands"
      ? "Netherlands Tour Packages"
      : selectedCountry === "norway"
      ? "Norway Tour Packages"
      : selectedCountry === "portugal"
      ? "Portugal Tour Packages"
      : selectedCountry === "romania"
      ? "Romania Tour Packages"
      : selectedCountry === "sweden"
      ? "Sweden Tour Packages"
      : selectedCountry === "uk"
      ? "UK Tour Packages"
      : selectedCountry === "spain"
      ? "Spain Tour Packages"
      : selectedCountry === "switzerland"
      ? "Spain Tour Packages"
      : selectedCountry === "andrapradesh"
      ? "Andra pradesh Tour Packages"
      : selectedCountry === "arunachalpradesh"
      ? "Arunachal pradesh Tour Packages"
      : selectedCountry === "assam"
      ? "Assam Tour Packages"
      : selectedCountry === "bihar"
      ? "Bihar Tour Packages"
      : selectedCountry === "chhattishgarh"
      ? "Chhattishgarh Tour Packages"
      : selectedCountry === "goa"
      ? "Goa Tour Packages"
      : selectedCountry === "gujarat"
      ? "Gujarat Tour Packages"
      : selectedCountry === "haryana"
      ? "Haryana Tour Packages"
      : selectedCountry === "himachalpradesh"
      ? "Himachal Pradesh Tour Packages"
      : selectedCountry === "jharkhand"
      ? "Jharkhand Pradesh Tour Packages"
      : selectedCountry === "karnataka"
      ? "Karnataka Tour Packages"
      : selectedCountry === "kerala"
      ? "Kerala Tour Packages"
      : selectedCountry === "madhyapradesh"
      ? "Madhya Pradesh Tour Packages"
      : selectedCountry === "maharashtra"
      ? "Maharashtra Tour Packages"
      : selectedCountry === "manipur"
      ? "Manipur Tour Packages"
      : selectedCountry === "meghalaya"
      ? "Meghalaya Tour Packages"
      : selectedCountry === "mizoram"
      ? "Mizoram Tour Packages"
      : selectedCountry === "nagaland"
      ? "Nagaland Tour Packages"
      : selectedCountry === "odisha"
      ? "Odisha Tour Packages"
      : selectedCountry === "punjab"
      ? "Punjab Tour Packages"
      : selectedCountry === "rajasthan"
      ? "Rajasthan Tour Packages"
      : selectedCountry === "sikkim"
      ? "Sikkim Tour Packages"
      : selectedCountry === "tamilnadu"
      ? "Tamilnadu Tour Packages"
      : selectedCountry === "telangana"
      ? "Telangana Tour Packages"
      : selectedCountry === "tripura"
      ? "Tripura Tour Packages"
      : selectedCountry === "uttarpradesh"
      ? "Uttar Pradesh Tour Packages"
      : selectedCountry === "uttarakhand"
      ? "Uttarakhand Tour Packages"
      : selectedCountry === "westbengal"
      ? "West Bengal Tour Packages"
      : selectedCountry === "andamanandnicobarislands"
      ? "Andaman and Nicobar Islands Packages"
      : selectedCountry === "chandigarh"
      ? "Chandigarh Tour Packages"
      : selectedCountry === "lakshadweep"
      ? "Lakshadweep Tour Packages"
      : selectedCountry === "delhi"
      ? "Delhi Tour Packages"
      : selectedCountry === "pondichery"
      ? "Pondichery Tour Packages"
      : selectedCountry === "jammuandkashmir"
      ? "Jammu and Kashmir Tour Packages"
      : selectedCountry === "ladakh"
      ? "Ladakh Tour Packages"
      : selectedCountry === "india"
      ? "India Tour Packages"
      : "OUR PACKAGES"}
          </h1>
          <p className="mini-heading">Explore Now</p>
        </div>
      </section>
      {/* Banner Section End */}

      {/* Package Section */}
      <section className="package-sec packagess">
        <div className="container-xxl">
          <div className="row pack-row">
            <div className="filter-con col-md-3 d-none d-md-block">
              <div className="filter-box">
                <p className="mini-heading">Search :</p>
                <form method="get" action="">
                  <input
                    className="form-control search-input"
                    name="search"
                    // placeholder="Type Here"
                    placeholder={search ? search : "Type Here"}
                    onChange={(e) => setSearch(e.target.value)}
                    // value={search}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSearch(e);
                      }
                    }}
                  />
                </form>
                <br />
                {/* Rest of your code... */}
                <div className="filter-3">
                  <p className="mini-heading">Category :</p>
                  <div className="row">
                    <form method="get" action="">
                      <select
                        name="cat"
                        id=""
                        onChange={handleCategory}
                        value={selectedInt}
                      >
                        <option value="">All Category</option>

                        {catData.length > 0 && (
                          <>
                            {catData.map((country) => (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </form>
                  </div>
                </div>

                <div className="filter-1">
                  <p className="mini-heading">Destination :</p>
                  <form method="get" action="">
                    <select
                      name="country"
                      id=""
                      onChange={handleCountryChange}
                      value={selectedCountry}
                    >
                      <option value="">All Destination</option>
                      {selectedInt !== "1" && selectedInt !== "2" &&
                      <>
                        {countryData.map((data, index) => (
                          <option key={index} value={data.value}>
                            {data.name}
                          </option>
                        ))} 
                      </>
                      }

                      {selectedInt === "1" && (
                        <>
                        aa
                          {countryData1.map((data, index) => (
                            <option key={index} value={data.value}>
                              {data.name}
                            </option>
                          ))}
                        </>
                      )}
                      {selectedInt === "2" && (
                        <>
                          {countryData2.map((data, index) => (
                            <option key={index} value={data.value}>
                              {data.name}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </form>
                </div>

                <div className="filter-3">
                  <p className="mini-heading">Sub Category :</p>
                  <div className="row">
                    <form method="get" action="">
                      <select
                        name="sub"
                        id=""
                        onChange={handleSubCategory}
                        value={selectedSubcat}
                      >
                        <option value="">All Sub Category</option>
                        {subCatData.length > 0 && (
                          <>
                            {subCatData.map((country) => (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </form>
                  </div>
                </div>

                <div className="filter-5">
                  <p className="mini-heading">Durations :</p>
                  <div className="row">
                    <form method="get" action="">
                      <select
                        name="duration"
                        id=""
                        onChange={handleDuration}
                        value={duration}
                      >
                        <option value="">All Durations</option>
                        <option value="5">Upto 5 days</option>
                        <option value="10">Upto 10 days</option>
                        <option value="15">Upto 15 days</option>
                        <option value="20">Upto 20 days</option>
                        <option value="25">Upto 25 days</option>
                        <option value="30">Upto 30 days</option>
                      </select>
                    </form>
                  </div>
                </div>

                <div className="filter-4">
                  <p className="mini-heading">Max Price :</p>
                  <form method="get" action="">
                    <div className="amt-box">
                      {showAmount && (
                        <span id="amount-val" className="show-amt">
                          {currentValue}
                        </span>
                      )}
                      <input
                        type="range"
                        id="amount"
                        min={0}
                        max={100000}
                        // value={currentValue}
                        // onChange={handleAmount}
                        onInput={handleAmount}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-9">
              {/* <?php */}
              {/* if (count($data) > 0) {
          foreach ($data as $p) {
            $packageSlug = generateSlug($p['name']);
            $packageUrl = "package-details.php/$packageSlug";
            ?> */}
              {!noresult ? (
                <>
                  {currentItems
                    // .sort(() => Math.random() - 0.5)
                    .map((pack) => (
                      <div className="package-box mb-4">
                        <div className="item-box">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="item-img">
                                <Link
                                  className="text-dark"
                                  to={`/package-details/${pack.id}/${pack.name
                                    ?.trim()
                                    .replace("/", "-")
                                    .replace(/\s/g, "-")
                                    .toLowerCase()}`}
                                >
                                  <img
                                    className="img-fluid"
                                    src={`/uploads/${pack.img1}`}
                                    alt="package"
                                    title="package"
                                    srcset=""
                                  />
                                </Link>

                                {/* <?php
    
                          $currentURL = "http://$_SERVER[HTTP_HOST]" . "/aspire-web";
    
                          $link = "🌟 Welcome to Aspire Holidays! 🌟 \n\n Thank you for choosing us to fulfill your travel dreams. Our team is excited to assist you on your journey to unforgettable destinations. Whether you're planning a relaxing beach getaway or an adventure-filled expedition, we're here to make it a reality. Feel free to ask any questions, request travel tips, or share your plans with us. Your dream vacation begins here!Stay tuned for exclusive travel offers, insider insights, and more exciting updates.Happy travels, The Aspire Holidays Team " . $currentURL . "/package-details/" . $p['id']; ?> */}

                                <div className="img-cont">
                                  <div className="row">
                                    <div className="col-6">
                                      <p className="para">
                                        <span className="fa fa-clock"></span>
                                        &nbsp;
                                        {pack.tdays} Days 
                                      </p>
                                    </div>
                                    <div className="col-6 text-end">
                                      <Link
                                        to={`mailto:?subject=Welcome to Aspire Holidays!&body=${encodeURIComponent(
                                          msg + " " + config.shareUrl + pack.id
                                        )}`}
                                      >
                                        <span className="fa fa-envelope"></span>{" "}
                                        &nbsp;
                                      </Link>
                                      <Link
                                        to={`whatsapp://send?text=${encodeURIComponent(
                                          msg + " " + config.shareUrl + pack.id
                                        )}`}
                                        data-action="share/whatsapp/share"
                                        target="_blank"
                                      >
                                        <span className="fa-brands fa-whatsapp"></span>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="item-cont">
                                <Link
                                  className="text-dark"
                                  to={`/package-details/${pack.id}/${pack.name
                                    ?.trim()
                                    .replace("/", "-")
                                    .replace(/\s/g, "-")
                                    .toLowerCase()}`}
                                >
                                  <h2 className="mini-heading">
                                    {pack.name.substring(0, 70)}..
                                  </h2>
                                </Link>
                                <p className="para">
                                  <span className="fa fa-location-dot"></span>{" "}
                                  &nbsp;
                                  {countryData.map(
                                    (country) =>
                                      country.value ===
                                        pack.country
                                          .replace(/\s/g, "")
                                          .toLowerCase() && country.name
                                  )}
                                  {/* {pack.country} */}
                                </p>
                                <hr />
                                <p className="para">
                                  <div className="in-box">
                                    <h6>
                                      <b>Inclusions</b>
                                    </h6>

                                    <div className="in-inner d-flex align-items-center">
                                      {pack.visa === 1 && (
                                        <img
                                          className="icons-img"
                                          src="asset/icons/visa.png"
                                          alt="visa"
                                          title="visa"
                                          height="50px"
                                          width="50px"
                                        />
                                      )}

                                      {pack.passport === 1 && (
                                        <img
                                          className="icons-img"
                                          src="asset/icons/pass.png"
                                          alt="passport"
                                          title="passport"
                                          height="50px"
                                          width="50px"
                                        />
                                      )}

                                      {pack.ticket === 1 && (
                                        <img
                                          className="icons-img"
                                          src="asset/icons/ticket.png"
                                          alt="ticket"
                                          title="ticket"
                                          height="50px"
                                          width="50px"
                                        />
                                      )}

                                      {pack.transport === 1 && (
                                        <img
                                          className="icons-img"
                                          src="asset/icons/trans.png"
                                          alt="transport"
                                          title="transport"
                                          height="50px"
                                          width="50px"
                                        />
                                      )}

                                      {pack.hotel === 1 && (
                                        <img
                                          className="icons-img"
                                          src="asset/icons/hotel.png"
                                          alt="hotel"
                                          title="hotel"
                                          height="50px"
                                          width="50px"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </p>

                                <div className="price-cont">
                                  <Link
                                    to={`/package-details/${pack.id}/${pack.name
                                      ?.trim()
                                      .replace("/", "-")
                                      .replace(/\s/g, "-")
                                      .toLowerCase()}`}
                                    className="btns"
                                  >
                                    Details
                                  </Link>
                                  <div>
                                    <p className="para">From</p>
                                    <p className="sub-heading">
                                      ₹{pack.amount}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <h3 class="text-center text-warning mt-2 mb-4">
                  No Packages found
                </h3>
              )}

              {/* //     <?php
        //   }
        // } else { */}
              {/* //   echo '<h3 className="text-center text-warning">No packages found</h3>';
        // } ?> */}
            </div>
          </div>
        </div>
      </section>

      {totalPages > 1 && (
        <>
          {currentPage === 1 && (
            <div className="pagesBox">
              <a className="pagination">
                <button className="btns">{currentPage}</button>
              </a>
              <a className="pagination">
                <button
                  className="btns-navy"
                  onClick={SingleArrowTwo}
                  disabled={currentPage === totalPages}
                >
                  &gt;
                </button>
              </a>
              <a className="pagination">
                <button className="btns-navy" onClick={DoubleArrowTwo}>
                  &gt;&gt;
                </button>
              </a>
            </div>
          )}
          {currentPage !== 1 && currentPage !== totalPages && (
            <div className="pagesBox">
              <a className="pagination">
                <button className="btns-navy" onClick={DoubleArrowOne}>
                  {" "}
                  &lt;&lt;
                </button>
              </a>
              <a className="pagination">
                <button
                  className="btns-navy"
                  onClick={SingleArrowOne}
                  disabled={currentPage === 1}
                >
                  &lt;
                </button>
              </a>
              <a className="pagination">
                <button className="btns">{currentPage}</button>
              </a>
              <a className="pagination">
                <button
                  className="btns-navy"
                  onClick={SingleArrowTwo}
                  disabled={currentPage === totalPages}
                >
                  &gt;
                </button>
              </a>
              <a className="pagination">
                <button className="btns-navy" onClick={DoubleArrowTwo}>
                  &gt;&gt;
                </button>
              </a>
            </div>
          )}
          {currentPage === totalPages && (
            <div className="pagesBox">
              <a className="pagination">
                <button className="btns-navy" onClick={DoubleArrowOne}>
                  &lt;&lt;
                </button>
              </a>
              <a className="pagination">
                <button className="btns-navy" onClick={SingleArrowOne}>
                  &lt;
                </button>
              </a>
              <a className="pagination">
                <button className="btns">{totalPages}</button>
              </a>
            </div>
          )}
        </>
      )}
      <Footer />
    </>
  );
};

export default OurPackages;
