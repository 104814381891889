import Footer from "../common/Footer";
import Header from "../common/Header";
import "../css/PrivacyPolicy.css";

const Termspage = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="privacy-policy-container">
          <h1 className="privacy-policy-heading">Terms and Conditions</h1>
          <h2 className="section-headings">OVERVIEW</h2>

          <p className="text-muted privacy-para-con">
            Welcome to Travel Adventure! These Terms and Conditions outline
            the rules and regulations for the use of our website and services.
            By accessing this website and using our services, you accept these
            Terms and Conditions in full. If you disagree with any part of these
            terms, please do not use our website.
          </p>
          <p className="text-muted privacy-para-con">
            Please read these Terms of Service carefully before accessing or
            using our website. By accessing or using any part of the site, you
            agree to be bound by these Terms of Service. If you do not agree to
            all the terms and conditions of this agreement, then you may not
            access the website or use any services. If these Terms of Service
            are considered an offer, acceptance is expressly limited to these
            Terms of Service.
          </p>
          {/* section1 */}

          <h2 className="section-headings">SECTION 1 - GENERAL CONDITIONS</h2>
          <p className="text-muted privacy-para-con">
            We reserve the right to refuse service to anyone for any reason at
            any time.
          </p>
          <p className="text-muted privacy-para-con">
            You understand that your content (not including credit card
            information), may be transferred unencrypted and involve (a)
            transmissions over various networks; and (b) changes to conform and
            adapt to technical requirements of connecting networks or devices.
            Credit card information is always encrypted during transfer over
            networks.
          </p>
          <p className="text-muted privacy-para-con">
            You agree not to reproduce, duplicate, copy, sell, resell or exploit
            any portion of the Service, use of the Service, or access to the
            Service or any contact on the website through which the service is
            provided, without express written permission by us.
          </p>
          <p className="text-muted privacy-para-con">
            The headings used in this agreement are included for convenience
            only and will not limit or otherwise affect these Terms.
          </p>
          {/* section2 */}

          <h2 className="section-headings">
            SECTION 2 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
          </h2>
          <p className="text-muted privacy-para-con">
            We are not responsible if information made available on this site is
            not accurate, complete or current. The material on this site is
            provided for general information only and should not be relied upon
            or used as the sole basis for making decisions without consulting
            primary, more accurate, more complete or more timely sources of
            information. Any reliance on the material on this site is at your
            own risk.{" "}
          </p>
          <p className="text-muted privacy-para-con">
            This site may contain certain historical information. Historical
            information, necessarily, is not current and is provided for your
            reference only. We reserve the right to modify the contents of this
            site at any time, but we have no obligation to update any
            information on our site. You agree that it is your responsibility to
            monitor changes to our site.
          </p>
          {/* section3 */}

          <h2 className="section-headings">
            SECTION 3 - MODIFICATIONS TO THE SERVICE AND PRICES
          </h2>
          <p className="text-muted privacy-para-con">
            Prices for our travel packages are subject to change without notice.
          </p>
          <p className="text-muted privacy-para-con">
            We reserve the right at any time to modify or discontinue the
            Service (or any part or content thereof) without notice at any time.
          </p>
          <p className="text-muted privacy-para-con">
            We shall not be liable to you or to any third-party for any
            modification, price change, suspension or discontinuance of the
            Service.
          </p>
          {/* section4 */}

          <h2 className="section-headings">SECTION 4 - TRAVEL PACKAGES</h2>
          <p className="text-muted privacy-para-con">
            Certain travel packages may be available exclusively through our
            website. These packages may have limited availability and are subject
            to our booking and cancellation policies.
          </p>
          <p className="text-muted privacy-para-con">
            We make every effort to accurately describe the features and
            accommodations included in our travel packages. However, we cannot
            guarantee that all amenities will be available at all times during
            your trip.
          </p>
          <p className="text-muted privacy-para-con">
            We reserve the right to modify the contents of our travel packages
            at any time. Any changes will be communicated to you prior to your
            departure date.
          </p>
          {/* section5 */}

          <h2 className="section-headings">SECTION 5 - BOOKING AND PAYMENT</h2>
          <p className="text-muted privacy-para-con">
            To book a travel package, you must provide accurate and complete
            information during the booking process.
          </p>
          <p className="text-muted privacy-para-con">
            Full payment is required at the time of booking unless otherwise
            specified. We accept various payment methods, including credit cards
            and online transfers.
          </p>
          <p className="text-muted privacy-para-con">
            Once your booking is confirmed, you will receive a confirmation
            email with details of your itinerary. Please review this information
            carefully and contact us immediately if you notice any discrepancies.
          </p>
          {/* section6 */}

          <h2 className="section-headings">SECTION 6 - CANCELLATION AND REFUNDS</h2>
          <p className="text-muted privacy-para-con">
            Cancellation policies vary depending on the type of travel package
            and the time of cancellation. Please refer to our cancellation policy
            for specific details.
          </p>
          <p className="text-muted privacy-para-con">
            Refunds, if applicable, will be processed according to our refund
            policy. In some cases, cancellation fees may apply.
          </p>
          <p className="text-muted privacy-para-con">
            We reserve the right to cancel a travel package due to unforeseen
            circumstances or events beyond our control. In such cases, you will
            receive a full refund of the amount paid.
          </p>
          {/* section7 */}

          <h2 className="section-headings">SECTION 7 - LIABILITY</h2>
          <p className="text-muted privacy-para-con">
            We shall not be liable for any loss, injury, or damage to person
            or property arising from your participation in our travel packages.
          </p>
          <p className="text-muted privacy-para-con">
            You agree to indemnify and hold us harmless from any claims, damages,
            or expenses resulting from your use of our services or your violation
            of these terms and conditions.
          </p>
          {/* section8 */}

          <h2 className="section-headings">SECTION 8 - CONTACT INFORMATION</h2>
          <p className="text-muted privacy-para-con">
            Questions about the Terms of Service should be sent to us at Contact:
            +91 9362266666 / Email: info@aspireholidays.in
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Termspage;
