// CommonHelmet.js
import React from 'react';
import { Helmet } from 'react-helmet';

// Define schema objects as constants
 {/* Breadcrumb schema */}
const breadcrumbSchema = {
  "@context": "https://schema.org/", 
  "@type": "BreadcrumbList", 
  "itemListElement": [{
    "@type": "ListItem", 
    "position": 1, 
    "name": "Aspire Holidays-Best Travel Agency in coimbatore",
    "item": "https://aspireholidays.in/"  
  },{
    "@type": "ListItem", 
    "position": 2, 
    "name": "About Us",
    "item": "https://aspireholidays.in/about"  
  },{
    "@type": "ListItem", 
    "position": 3, 
    "name": "International Tour Packages",
    "item": "https://aspireholidays.in/international-tour-packages"  
  },{
    "@type": "ListItem", 
    "position": 4, 
    "name": "Domestic Tour Packages",
    "item": "https://aspireholidays.in/domestic-tour-packages"  
  },
  {
    "@type": "ListItem", 
    "position": 5, 
    "name": "Honeymoon Tour Packages",
    "item": "https://www.aspireholidays.in/package?sub=2"  
  },
  {
    "@type": "ListItem", 
    "position": 6, 
    "name": "Pilgrimage Tour Packages",
    "item": "https://www.aspireholidays.in/package?sub=8"  
  },
  {
    "@type": "ListItem", 
    "position": 7, 
    "name": "Cruises Tour Packages",
    "item": "https://www.aspireholidays.in/package?sub=3"  
  },{
    "@type": "ListItem", 
    "position": 8, 
    "name": "Services",
    "item": "https://aspireholidays.in/service"  
  },{
    "@type": "ListItem", 
    "position": 9, 
    "name": "Blogs",
    "item": "https://aspireholidays.in/blog"  
  },
  {
    "@type": "ListItem", 
    "position": 10, 
    "name": "Contact",
    "item": "https://aspireholidays.in/travel-agency"  
  }]
};

    {/* News Article schema */}
const newsArticleSchema = {
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://aspireholidays.in/"
  },
  "headline": "Best Travel Agencies in coimbatore",
  "description": "Best Travel Agent in Coimbatore where you can find domestic & international package. Plan your trip as you wish. A single window to your travel needs.",
  "image": "http://aspireholidays.in/asset/images/others/logo.svg",  
  "author": {
    "@type": "Organization",
    "name": ""
  },  
  "publisher": {
    "@type": "Organization",
    "name": "",
    "logo": {
      "@type": "ImageObject",
      "url": ""
    }
  },
  "datePublished": ""
};

    {/* local business schema */}
    const localBusinessSchema = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Aspire Holidays",
      "image": "https://aspireholidays.in/asset/images/others/logo.svg",
      "@id": "",
      "url": "https://aspireholidays.in/",
      "telephone": "9362266666",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Second Floor, Nagammai Building, Dr Nanjappa Road, Near Park Gate Roundana, Park Gate, Ram Nagar",
        "addressLocality": "Coimbatore",
        "postalCode": "641018",
        "addressCountry": "IN"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": 11.0014944,
        "longitude": 76.9627405
      },
      "openingHoursSpecification": {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
        ],
        "opens": "00:00",
        "closes": "23:59"
      },
      "sameAs": [
        "https://facebook.com/aspireholidays",
        "https://www.instagram.com/aspireholidays.in/",
        "https://www.youtube.com/@AspireHolidays",
        "https://www.linkedin.com/in/aspire-holidays-52b626144/?originalSubdomain=in",
        "https://aspireholidays.in/"
      ] 
    };
    {/* organizationSchema*/}

    const organizationSchema = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Aspire Holidays",
      "legalName": "Best Travel Agency in Coimbatore",
      "email": "info@aspireholidays.in",
      "url": "https://aspireholidays.in/",
      "logo": "https://aspireholidays.in/asset/images/others/logo.svg",
      "sameAs": [
        "https://facebook.com/aspireholidays",
        "https://www.instagram.com/aspireholidays.in/",
        "https://www.youtube.com/@AspireHolidays",
        "https://aspireholidays.in/"
      ],
      "address": {
        "addressLocality": "Coimbatore",
        "postalCode": "641018",
        "streetAddress": "Second Floor, Nagammai Building, Dr Nanjappa Road, Near Park Gate Roundana, Park Gate, Ram Nagar"
      }
    };

     {/* Website Schema */}
     const websiteSchema = {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "name": "Aspire Holidays",
      "url": "https://aspireholidays.in/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://aspireholidays.in/{search_term_string}",
        "query-input": "required name=search_term_string"
      }
    };
    
const CommonHelmet = ({ pageTitle = "Default Page Title", pageDescription = "Default meta description", pageKeywords = "default, keywords" }) => {
  const currentUrl = window.location.href;

  const metaContent = {
    des: pageDescription,
    key: pageKeywords,
    ogt: pageTitle,
    og: pageDescription,
    logo: "https://aspireholidays.in/asset/images/others/logo.svg",
  };

  return (
    <Helmet>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href={"favicon.ico"} />
      <link rel="canonical" href={currentUrl} />
      <meta name="robots" content="index, follow" />
      <title>{metaContent.ogt}</title>
      <meta name="keywords" content={metaContent.key} />
      <meta name="description" content={metaContent.
        des} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={metaContent.ogt} />
      <meta property="og:description" content={metaContent.og} />
      <meta property="og:image" content={metaContent.logo} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Best Travel Agency in coimbatore-Aspire Holidays" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaContent.ogt} />
      <meta name="twitter:description" content={metaContent.og} />
      <meta name="twitter:image" content={metaContent.logo} />

      {/* Robot tag */}
      <meta name="robots" content="index, follow" />

        {/* Breadcrumb schema */}
        <script type="application/ld+json">
        {JSON.stringify(breadcrumbSchema)}
      </script>
      
      {/* News Article schema */}
      <script type="application/ld+json">
        {JSON.stringify(newsArticleSchema)}
      </script>
        {/* Local business schema */}
        <script type="application/ld+json">
        {JSON.stringify(localBusinessSchema)}
      </script>
      
        {/* Organization schema*/}
        <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
        {/* website schema*/}
        <script type="application/ld+json">
        {JSON.stringify(websiteSchema)}
      </script>  
       {/* Google Tag Manager */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-WQEJCJR0VX"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-WQEJCJR0VX');
        `}
      </script>
    </Helmet>
  );
};

export default CommonHelmet;
