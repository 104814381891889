import React, { useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";

import CommonHelmet from "./Components/CommonHelmet";

import { Alert } from "react-bootstrap";
import axios from "axios";
import config from "./config";

const Contactsalem = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState("");
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  const handleInput = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  function alertBox() {
    if (show) {
      return (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          {errors}
        </Alert>
      );
    }
  }

  function msgBox() {
    if (showMsg) {
      return (
        <Alert variant="success" onClose={() => setShowMsg(false)} dismissible>
          {errors}
        </Alert>
      );
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios
        .post(config.apiUrl + "contactmail.php", values)
        .then((res) => {
          console.log(0);
          if (res.data.success) {
            console.log(1);
            document.querySelector(".form").reset();
            setErrors(res.data.success);
            setShowMsg(true);
            setValues({});
          } else {
            console.log(3);
            setErrors("Please Try again Later");
            setShow(true);
          }
        })
        .catch((err) => {
          console.log(4);
          console.log(err);
          setErrors("Something Wrong Please Try again Later");
          setShow(true);
        });
    } catch {
      console.log("Error");
    }
  };
  return (
    <>
      <CommonHelmet
        pageTitle="Best Travel Agency in Salem|Tour Packages"
        pageDescription="Travel Agency in Salem, offers the most memorable tour packages for your travel experience.Book Tour Packages
      "
        pageKeywords="Best International Packages from Salem, Best Domestic Package from
        Salem, Bali Package from Salem, Thailand Package from Salem, Maldives Package from
        Salem
      "
      />
      <Header />
      <div className="Overflowhide">
        {/* <!-- Banner Section  --> */}
        <section className="cont-banner">
          <div className="banner-box">
            <h2 className="main-heading">TRAVEL AGENCY IN SALEM</h2>
            <p className="mini-heading">Contact Us</p>
          </div>
        </section>

        <div className="mt-4 ">
          <ul className="travel-main-sec">
            <li className="contact-home-interlink">
              <a href="/">Home</a>
            </li>
            <li className="contact-travel">&gt;</li>
            <li className="contact-travel">Travel Agency in Salem</li>
          </ul>
        </div>

        {/* <!-- Contact Section --> */}
        <section className="cont-sec">
          <div className="cont-sec-inn container">
            <div className="row">
              <div className="col-md-4">
                <div className="add-box">
                  <div className="cont-head">
                    <div className="circle">
                      <span className="fa-solid fa-xl fa-location-arrow"></span>
                    </div>
                    <p className="mini-heading">REACH US</p>
                  </div>
                  <div className="add-1">
                    <p className="mini-heading">SALEM</p>
                    <address>
                      5/34 - A, MVS Nagar 4th Cross, <br/>
                      Thalavaipatti, Salem - 636302
                    </address>
                  </div>
                  {/* <div className="add-2">
                    <p className="mini-heading">CHENNAI</p>
                    <address>
                      Prince Centre, Ground Floor, New No: 248, <br />
                      Pathari Road, Anna Salai, <br />
                      Chennai, TamilNadu 600006 <br />
                    </address>
                  </div> */}
                </div>
              </div>
              <div className="col-md-4 mb-5 contact-links">
                <div className="cont-head">
                  <div className="circle">
                    <span className="fa-solid fa-xl fa-phone"></span>
                  </div>
                  <p className="mini-heading">CALL US</p>
                </div>
                <div className="call-1">
                  <p className="mini-heading">Mobile Number</p>
                  <a href="tel:9362266666">9362266666</a>
                  <br />
                  <a href="tel:9940888656">9940888656</a>
                </div>
                {/* <div className="call-2">
                  <p className="mini-heading">Office Number</p>
                  <a href="tel:9362266666">9362266666</a>
                  <br />
                  <a href="tel:9514433334">9514433334</a>
                  <br />
                  <a href="tel:9751166660">9751166660</a>
                </div> */}
              </div>
              <div className="col-md-4 call-box">
                <div className="cont-head">
                  <div className="circle">
                    <span className="fa-solid fa-xl fa-envelope"></span>
                  </div>
                  <p className="mini-heading">MAIL US</p>
                </div>
                <div className="mail">
                  <p className="mini-heading">MAIL</p>

                  <p>
                    Write to this email for a detailed quotation <br />
                    <a href="mailto:info@aspireholidays.in">
                      info@aspireholidays.in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Contact section end --> */}
        <div className="container mt-5 mb-5">
          <h2 className="travel-agency-contact-main">
            <b>
              Best Travel Agency in{" "}
              <a href="/international-tour-packages">Salem</a>
            </b>
          </h2>
          <br></br>
          <p className="para">
            Travel, once linked with relaxation and enjoyment, now holds greater
            importance in our fast-paced world. Nowadays, travel serves multiple
            purposes and provides a variety of experiences. While individuals
            can efficiently plan their trips, we, as the top{" "}
            <b>Travel Agency in Salem</b>, offer an extensive range of travel
            and tourism services to ensure a smooth journey from the initial
            planning stages to the return trip.
          </p>
          <p className="para">
            Embarking on a lengthy journey can feel like a daunting task, from
            organizing accommodations to managing finances. But why go through
            the trouble of in-depth research when we're here to curate the most
            efficient plan and schedule for you? Let us take care of all the
            details seamlessly, so you can savor your travel adventures without
            any worries.
          </p>
          <br></br>
          <h2 className="travel-agency-contact">
            How Do You Choose a Trusted Travel Agency in Salem?
          </h2>
          <p className="para para-linter">
            Choosing a <a href="/">Travel Agency</a> has become quite a
            challenge nowadays, as you rely on them to create memorable
            experiences for your leisure time. However, this process doesn't
            have to be overly complicated. While statistics may provide valuable
            insights, it is important to consider other aspects of a company
            beyond the sheer number of customers they have served.
          </p>
          <p className="para">
            When selecting a travel agents, it is crucial to prioritize quality
            over quantity. The most important aspect to consider is the quality
            of service provided by the agency, rather than the number of options
            they offer. Always remember that quality should be given utmost
            importance when choosing a travel agency.
          </p>
          <p className="para">
            Read the reviews, talk to their guests and employees, focus on the
            details, and you’ll know all that you must know about the company.
            Established in 2010, it’s been a significant period since Aspire
            Holidays has been serving people to make their special time extra
            special.
          </p>
          <p className="para">
            But it’s more than that. We genuinely care about you! Our commitment
            to exceptional service is evident in our offerings, which is why
            people always come back for more.
          </p>
          <br></br>
          <h2 className="travel-agency-contact">
            Why is Aspire Holidays the Trusted Travel Agency in Salem ?
          </h2>
          <p className="para">
            Looking for the top <b>travel agency in Salem?</b> Your search ends
            here with Aspire Holidays. With an impressive Google Reviews rating
            of 4.8 and a large customer base of over 10,000 satisfied travelers,
            we are honored to be the preferred option in the area. Our
            commitment to providing exceptional service is evident in every
            single detail.
          </p>
          <p className="para">
            We offer a wide range of domestic, international, and honeymoon
            vacation packages, along with personalized service to meet all your
            travel needs. Our experienced team is dedicated to creating flawless
            and memorable journeys, focusing on customer happiness and offering
            efficient assistance every step of the way.
          </p>
          <p className="para internlink">
            Choose Aspire Holidays as your trusted travel partner from Salem and
            embark on a journey to your favorite destinations filled with
            unforgettable adventures. Experience the best travel agents in Salem
            with Aspire Holidays, offering the{" "}
            <a href="/international-tour-packages">
              Best international Packages
            </a>
            ,<a href="/domestic-tour-packages"> Best Domestic Packages</a>,{" "}
            <b>
              including Bali, Thailand, Maldives, Singapore, Malaysia, Dubai,
              Manali, Kashmir, and
              <a href="/package?sub=2">Best Honeymoon Tours</a>
            </b>
          </p>
          <br></br>
          <h2 className="travel-agency-contact">
            What Services Does Aspire Holidays Offer?
          </h2>
          <p className="para">
            Our <b>Travel Agency in Salem</b> not only arranges domestic trips,
            but also plans exciting international journeys, guaranteeing endless
            fun without limits. We go above and beyond to take care of you,
            whether you're sightseeing in a city for a day or enjoying a
            week-long vacation overseas.
          </p>
          <p className="para">
            At Aspire Holidays, we provide a variety of services such as cruise
            packages to make sure you have the ultimate experience, no matter if
            you're traveling by land, air, or sea.
          </p>
          <p className="para">
            Discover the top travel agents in Salem at Aspire Holidays! We
            provide premier International Packages, top-notch Domestic Packages,
            and unforgettable destinations like Bali, Thailand, Maldives,
            Singapore, Malaysia, Dubai, Manali, Kashmir, and exclusive Honeymoon
            Tours.
          </p>
          <p className="para">
            Stay updated on our daily offers by following our Instagram and
            YouTube pages at Aspire Holidays.
          </p>
        </div>
        {/* <!-- Form  --> */}
        <section className="form-section">
          <div className="container">
            <div className="form-head">
              <h2 className="cursive-heading">
                Plan Your Next Trip with the Leading Travel Agency
              </h2>
              <p className="sub-heading">Get in Touch</p>
              <p className="para">
                Write to us for personalized travel advice or for information on
                group travel and last minute travel, all travel is insured and
                safe.
              </p>
            </div>

            <div
              className="form-box box-size"
              id="cont"
              onSubmit={handleSubmit}
            >
              <form className="form" method="post">
                {alertBox()}
                {msgBox()}
                <input
                  name="name"
                  type="text"
                  placeholder="Type your Name"
                  onChange={handleInput}
                  required
                />
                <input
                  name="email"
                  type="email"
                  placeholder="Insert your Email"
                  onChange={handleInput}
                  required
                />
                <textarea
                  name="message"
                  rows="5"
                  placeholder="Your Message"
                  onChange={handleInput}
                  required
                ></textarea>
                <div className="d-grid gap-2">
                  <button type="submit" className="btns cont-btn">
                    Send
                  </button>
                  <br />
                </div>
              </form>
            </div>
          </div>
        </section>

        <div className="map">
          <div className="row">
            <div className="col-12 con-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3292.320516405137!2d77.40160695000884!3d11.094662864003414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba909996d6a2387%3A0xdc9c231dc57d2ab2!2sAspire%20Holidays%20Salem!5e0!3m2!1sen!2sin!4v1714386058458!5m2!1sen!2sin"
                width="100%"
                height="600"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contactsalem;
