import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { useParams } from "react-router";
import axios from "axios";
import config from "./config";
import CommonHelmet from "./Components/CommonHelmet";
import { Helmet } from "react-helmet";
const BlogDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    axios.get(config.apiUrl + "blogs.php").then((res) => {
      setData(res.data.data);
    });
  }, []);

  useEffect(() => {
    const filteredData = data.filter((item) => item.id === parseInt(id));
    setFilter(filteredData[0] || {});   
  }, [data, id]);

  return (
    <>
      {filter.id == id && (
        <>
           <CommonHelmet
            pageTitle={filter.name}
            pageDescription={filter.mdes}
            pageKeywords={filter.mkey}
            
          />
              <Helmet>
            <meta name="robots" content="index, follow" />
          </Helmet>
          <Header />
      
       

          <div className="pd-main-sec position-relative">
            <div className="container">
              <div className="blog-img">
                <img
                  className="img-fluid"
                  src={`/uploads/blog/${filter.img}`}
                  alt="blogs"
                  title="blogs"
                />
              </div>

              <div className="detail-box">
                <p className="para">
                  {/* <?php echo $blog['date_created']; ?> */}{" "}
                  {filter.date_created}
                  <br /> <br />
                  {/* <?php echo $blog['content']; ?> */}
                  <span
                    className="blog-details-content "
                    dangerouslySetInnerHTML={{ __html: filter.content }}
                  ></span>
                </p>
                <br />
              </div>
            </div>
          </div>

          <Footer />
        </>
      ) 
      }
    </>
  );
};

export default BlogDetails;
