import React from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header";
import Footer from "./common/Footer";
import CommonHelmet from "./Components/CommonHelmet";
const Continental = () => {
  return (
    <>
      <CommonHelmet 
      pageTitle="International Tour Packages from Coimbatore- Book Tour Packages"
      pageDescription="International Tour Packages from coimbatore - Get the best deals and offers on world tour packages.Book your international Tour packages from Coimbatore.
      "
      pageKeywords="International Tour Packages from Coimbatore, Best Domestic Package
      from Coimbatore, Thailand Package from Coimbatore, Maldives Package from
      Coimbatore, Singapore Package from Coimbatore, Malaysia Package from Coimbatore,
      Dubai Package from Coimbatore, Best Honeymoon Tours from Coimbatore
      "
    />
    <Header />
      {/* <!-- Banner Section  --> */}
      <section className="cont-banner mb-5">
        <div className="banner-box">
          <h1 className="main-heading">INTERNATIONAL TOUR PACKAGES</h1>
          <p className="mini-heading">Explore Now</p>
        </div>
      </section>

      <div className="cnt-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-3">
              <Link to={"/asia"}>
                <div className="cnt-card as">
                  <div className="card-name">
                    <h2 className="mini-heading">ASIA TOUR PACKAGES</h2>
                  </div>
                  <div className="cnt-card-cont text-center">
                    <div>
                      <h2 className="sub-heading text-light">ASIA</h2>
                      <p className="text-light cnt-para">
                        Asia's beauty is as diverse as its landscapes, from the
                        serene elegance of cherry blossoms in Japan to the
                        vibrant colors of India's festivals.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <Link to={"/Pacific"}>
                <div className="cnt-card pr">
                  <div className="card-name">
                    <p className="mini-heading">PACIFIC REGION TOUR PACKAGES</p>
                  </div>
                  <div className="cnt-card-cont text-center">
                    <div>
                      <p className="sub-heading text-light">PACIFIC REGION</p>
                      <p className="text-light cnt-para">
                        The Pacific region's beauty is as diverse as its
                        landscapes, from idyllic South Pacific beaches to New
                        Zealand's rugged grandeur.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
            <Link to={"/middleeast"}>
                <div className="cnt-card me">
                  <div className="card-name">
                    <p className="mini-heading">MIDDLE EAST TOUR PACKAGES</p>
                  </div>
                  <div className="cnt-card-cont text-center">
                    <div>
                      <p className="sub-heading text-light">MIDDLE EAST</p>
                      <p className="text-light cnt-para">
                        The Middle East showcases a rich tapestry of beauty,
                        from the ancient wonders of Petra to the modern skyline
                        of Dubai.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
            <Link to={"/Africa"}>
                <div className="cnt-card af">
                  <div className="card-name">
                    <p className="mini-heading">AFRICA TOUR PACKAGES</p>
                  </div>
                  <div className="cnt-card-cont text-center">
                    <div>
                      <p className="sub-heading text-light">AFRICA</p>
                      <p className="text-light cnt-para">
                        Africa's beauty is a mosaic of stunning diversity, from
                        the iconic savannahs of the Serengeti to the vibrant
                        cultures of Marrakech.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
            <Link to={"/america"}>
                <div className="cnt-card am">
                  <div className="card-name">
                    <p className="mini-heading">AMERICA TOUR PACKAGES</p>
                  </div>
                  <div className="cnt-card-cont text-center">
                    <div>
                      <p className="sub-heading text-light">AMERICA</p>
                      <p className="text-light cnt-para">
                        America's beauty is a vast tapestry, from the towering
                        majesty of the Rockies to the bustling streets of New
                        York City.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
            <Link to={"/Europe"}>
                <div className="cnt-card eu">
                  <div className="card-name">
                    <p className="mini-heading">EUROPE TOUR PACKAGES</p>
                  </div>
                  <div className="cnt-card-cont text-center">
                    <div>
                      <p className="sub-heading text-light">EUROPE</p>
                      <p className="text-light cnt-para">
                        Europe's beauty unfolds across its historic cities like
                        Paris and the breathtaking landscapes of the Swiss Alps.
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Continental;
