import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { Link, useParams } from "react-router-dom";
import CommonHelmet from "./Components/CommonHelmet";
const MainService = () => {
    const [ourpackage, setOurpackage] = useState("")

    const {serv} = useParams()
    useEffect(() => {
      if(serv === "passport"){
        setOurpackage("passport")
      }
      if(serv === "visa"){
        setOurpackage("visa")
      }
      if(serv === "ticket"){
        setOurpackage("ticket")
      }
      if(serv === "hotel"){
        setOurpackage("hotel")
      }
      if(serv === "insurance"){
        setOurpackage("insurance")
      }
      if(serv === "currency"){
        setOurpackage("currency")
      }
    },[])
    useEffect(() => {
     window.scrollTo(0,0)
    },[])

  return (
    <>
       <CommonHelmet 
      pageTitle={(
      serv === "currency"?"best holiday sites| Thailand Package from Coimbatore":
      serv === "ticket"?"best travel tour companies |popular travel agencies in Coimbatore":
      serv === "visa"?"best online travel agency| Holiday travel agency in Coimbatore":
      serv === "passport"?"Best vacation websites| Best travel sites in Coimbatore":
      serv === "insurance"?"family vacation packages| Kashmir Package from Coimbatore":
      serv === "hotel"?"Best Honeymoon Tours| best travel booking sites in Coimbatore":"")}
      pageDescription={(
        serv === "currency"?"Discover the best holiday sites in Coimbatore. We are offering a best international package to Bali, Thailand and domestic packages like Manali, Kashmir.":
        serv === "ticket"?"Discover the best travel Agency in Coimbatore. We offering a best international packages to Bali, Thailand and domestic packages like Manali and Kashmir.":
        serv === "visa"?"Best Travel Agent in Coimbatore where you can find domestic & international packages. Plan your trip as you wish. A single window to your travel needs.":
        serv === "passport"?"Discover the top vacation websites for travel experiences in Coimbatore. offering an international package to Bali, Explore domestic like Manali, Kashmir":
        serv === "insurance"?"Discover the best family vacation packages in Coimbatore. We are offering a best international package to Bali, Thailand and domestic packages like Manali":
        serv === "hotel"?"Experience the Best Honeymoon Tours from Coimbatore. Exclusive international packages to Malaysia, Dubai and domestic like Manali, Maldives and Kashmir.":"")}
      pageKeywords={(
        serv === "currency"?"Travel Agents from Coimbatore, Best Travel Agency in Coimbatore, Best International Packages from Coimbatore, Best Domestic Package from Coimbatore, Kashmir Package from Coimbatore, Best Honeymoon Tours from Coimbatore":
        serv === "ticket"?"Travel Agents from Coimbatore, Best Travel Agency in Coimbatore, Best International Packages from Coimbatore, Best Domestic Package from Coimbatore, Bali Package from Coimbatore":
        serv === "visa"?"Travel Agents from Coimbatore, Best Travel Agency in Coimbatore, Best International Packages from Coimbatore, Best Domestic Package from Coimbatore":
        serv === "passport"?"Travel Agents from Coimbatore, Best Travel Agency in Coimbatore, Best International Packages from Coimbatore, Best Domestic Package from Coimbatore,Best Honeymoon Tours from Coimbatore":
        serv === "insurance"?"Travel Agents from Coimbatore, Best Travel Agency in Coimbatore, Best International Packages from Coimbatore, Best Domestic Package from Coimbatore, Bali Package from Coimbatore":
        serv === "hotel"?"Travel Agents from Coimbatore, Best Travel Agency in Coimbatore, Best International Packages from Coimbatore, Best Domestic Package from Coimbatore, Bali Package from Coimbatore":"")}
    />
    <Header />
      {/* <!-- Banner Section  --> */}
      <section className="cont-bannerr">
        <div className="banner-box">
          <h2 className="main-heading">Services</h2>
          <p className="mini-heading">Our Major Services</p>
        </div>
      </section>
      {/* <!-- Banner Section End  --> */}

      <nav className="tab-menu-sec">
        <div className="tab-menu" id="nav-tab" role="tablist">
          <div className="row">
            <div className="col-lg-6">
              <div className="row justify-content-center align-items-center">

                <div className="col-4 tab-box">
                  <Link className={`btn nav-link ${ourpackage === "passport" ? "active":""} `} to={"/service/passport"}>
                    <button
                       onClick={() => setOurpackage("passport")}

                      className={`btn nav-link ${ourpackage === "passport" ? "active":""} `}
                      id="nav-passport-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-passport"
                      role="tab"
                      aria-controls="nav-passport"
                      aria-selected={ourpackage === "passport" ? "true" : "false"}
                    >
                      <p className="tab-name">Passport</p>
                    </button>
                  </Link>
                </div>
                <div className="col-4 tab-box">

                  <Link  className={`btn nav-link ${ourpackage === "visa" ? "active":""} `} to={"/service/visa"}>
                    <button
                     onClick={() => setOurpackage("visa")}
                      className={`btn nav-link ${ourpackage === "visa" ? "active":""} `}
                      id="nav-visa-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-visa"
                      type="button"
                      role="tab"
                      aria-controls="nav-visa"
                      aria-selected={ourpackage === "visa" ? "true" :"false"}
                    >
                      <p className="tab-name">VISA</p>
                    </button>
                  </Link>

                </div>
                <div className="col-4 tab-box">
                  <Link className={`btn nav-link ${ourpackage === "ticket" ? "active":""} `} to={"/service/ticket"}>
                  <button
                    onClick={()=>setOurpackage("ticket")}
                    className={`btn nav-link ${ourpackage === "ticket" ? "active":""} `}
                    id="nav-ticket-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-ticket"
                    type="button"
                    role="tab"
                    aria-controls="nav-ticket"
                    aria-selected="false"
                  >
                    <p className="tab-name">Ticket</p>
                  </button>
                  </Link>
                  
                </div>

              </div>
            </div>
            <div className="col-lg-6">
              <div className="row justify-content-center align-items-center">

                <div className="col-4 tab-box">
                  <Link className={`btn nav-link ${ourpackage === "hotel" ? "active":""} `} to={"/service/hotel"}>
                  <button
                    onClick={()=>setOurpackage("hotel")}
                    className={`btn nav-link ${ourpackage === "hotel" ? "active":""} `}
                    id="nav-hotel-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-hotel"
                    type="button"
                    role="tab"
                    aria-controls="nav-hotel"
                    aria-selected="false"
                  >
                    <p className="tab-name">Hotel</p>
                  </button>
                  </Link>
                  
                </div>
                <div className="col-4 tab-box">
                  <Link className={`btn nav-link ${ourpackage === "insurance" ? "active":""} `} to={"/service/insurance"}>
                  <button
                    onClick={()=>setOurpackage("insurance")}
                    className={`btn nav-link ${ourpackage === "insurance" ? "active":""} `}
                    id="nav-insurance-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-insurance"
                    type="button"
                    role="tab"
                    aria-controls="nav-insurance"
                    aria-selected="false"
                  >
                    <p className="tab-name">Insurance</p>
                  </button>
                  </Link>
                  
                </div>
                <div className="col-4 tab-box">
                  <Link className={`btn nav-link ${ourpackage === "currency" ? "active":""} `} to={"/service/currency"}>
                  <button
                    onClick={()=>setOurpackage("currency")}
                    className={`btn nav-link ${ourpackage === "currency" ? "active":""} `}
                    id="nav-currency-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-currency"
                    type="button"
                    role="tab"
                    aria-controls="nav-currency"
                    aria-selected="false"
                  >
                    <p className="tab-name">Currency</p>
                  </button>
                  </Link>
                  
                </div>

              </div>
            </div>
          </div>
        </div>
        <div></div>
      </nav>

      <div className="tab-content" id="nav-tabContent">

        {/* passport */}
        <div
        className={`tab-pane fade${ourpackage === "passport" ? "show active":""} `}
          id="nav-passport"
          role="tabpanel"
          aria-labelledby="nav-passport-tab"
        >
          <div className="service-sec">
            <div className="service-box container">
              <div className="row">
                <div className="col-md-6">
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src="/asset/images/others/service-couple.webp"
                      alt="Passport"
                      title="Passport"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="cont-box">
                    <h2 className="sub-heading">Fresh Passport</h2>
                    <p className="para">
                      Applying a fresh passport is not a big deal, Provide the
                      required documents and our Executives will take care of
                      the applicant's appointment. Appoinment can be applied
                      through Online and the basic required documents are
                      mentioned below
                      <ul>
                        <li>Age Proof</li>
                        <li>Residential address proof</li>
                        <li>Proof of Identity</li>
                        <li>2 passport-sized photographs</li>
                        <li>
                          Attested photocopies of the first and last pages of
                          your existing passport booklet
                        </li>
                        <li>Original existing Passport booklet</li>
                        <li>
                          Appointment application receipt or the final page of
                          the online application page. This page is considered
                          proof of application fee payment and appointment
                          schedule.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div>
                  <h2 className="sub-heading">Tatkal Passport</h2>
                  <span>Planing to travel on urgent basis?</span>
                  <p className="para">
                    Tatkal passports are provided for those who need passport
                    urgently. Tatkal passport can be received faster than normal
                    passport. Additional processing fees is collected for Tatkal
                    passports. Documents Needed are same for the Normal passport
                    without any spelling or Change in address documents.
                  </p>
                </div>
                <div>
                  <h2 className="sub-heading">Renewal of passport</h2>
                  <p className="para">
                    Every passport has a tenure period, 5 yrs till 18 and 10
                    years above 18years of age. After every tenure Passport
                    should be renewed. Documents required for Passport renewal.
                    Appoinment can be applied through Online.
                  </p>
                  <ul>
                    <li>Age Proof</li>
                    <li>Residential address proof</li>
                    <li>Proof of Identity</li>
                    <li>2 passport-sized photographs</li>
                    <li>
                      Attested photocopies of the first and last pages of your
                      existing passport booklet
                    </li>
                    <li>Original existing Passport booklet</li>
                    <li>
                      Appointment application receipt or the final page of the
                      online application page. This page is considered proof of
                      application fee payment and appointment schedule.
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="sub-heading">Minors Passport</h2>
                  <p className="para">
                    Minor passports are issued only for applicants those are
                    below 18 years of age. Documents requied for applying Minor
                    passports are
                  </p>
                  <ul>
                    <li>
                      For minor applicants, current address proof document in
                      the name of parent can be provided.
                    </li>
                    <li>
                      It is advised to carry original and self-attested copies
                      of parents passport to Passport offices, in case parents
                      holds Indian Passport.
                    </li>
                    <li>
                      For minor applicants, documents can be attested by
                      parents.
                    </li>
                    <li>
                      The minor applicant is eligible for Non-ECR till he/she
                      attains the age of 18 years.
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="sub-heading">
                    Change The Name/Address On Passport
                  </h2>
                  <p className="para">
                    To change or update name or address in the Passport, due to
                    relocation or name change. Applicant must have any
                    government document that has done the present changes in
                    name or address. Any of these documents mention below can be
                    providded to update in Passport.
                  </p>
                  <ul>
                    <li>
                      Marriage certificate if available (original and a
                      photocopy)
                    </li>
                    <li>Self-attested photocopy of your spouse's passport</li>
                    <li>Proof of current address</li>
                    <li>
                      Old passport with self-attested photocopy of its first and
                      last two pages. It should also include the ECR/Non-ECR
                      page.
                    </li>
                    <li>
                      The old passport should contain a validity extension page
                      and the observation page, if any.
                    </li>
                    <li>
                      Nationalised bank Passbook front page with Seal and
                      signature of Bank authority.
                    </li>
                    <li>
                      A divorcee needs to submit a court-certified copy of the
                      divorce decree or copy of the divorce certificate
                      (self-attested) to change the surname in his/her passport
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* VISA */}
        <div
          className={`tab-pane fade${ourpackage === "visa" ? "show active":""} `}
          id="nav-visa"
          role="tabpanel"
          aria-labelledby="nav-visa-tab"
        >
          {/* <!-- Student Visa  --> */}
          <div className="service-sec">
            <div className="service-box container">
              <div className="row">
                <div className="col-md-6">
                  <div className="cont-box">
                    <h2 className="sub-heading">
                      PR Visa - (Permanent Resident Visa)
                    </h2>
                    <p className="para">
                      PR Visa is for the purpose of immigrating to other country
                      as permanent resident of the applying country. Each
                      country has its own limitations and rules for accepting
                      the PR Visa. Applying individual has certain terms and
                      eligibility Criteria to be qualified. To know more about
                      PR Visa for each and every country contact our Executive
                      or fill the details and Requirements in the given below
                      query form.
                    </p>
                    <ul>
                      <li>Study in USA</li>
                      <li>Study in UK</li>
                      <li>Study in Canada</li>
                      <li>Study in Australia</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src="https://img.freepik.com/free-photo/american-visa-document_1101-820.jpg?w=826&t=st=1688707403~exp=1688708003~hmac=25d545761f77006ec1d784d6fbaecddeb785b54363acdaecfd0ab959aacbaa42"
                      alt="Visa"
                      title="Visa"
                    />
                  </div>
                </div>
                <h2 className="sub-heading">Study Abroad / Student Visa</h2>
                <p className="para">
                  Student / Study Visa is applicable only for those are in need
                  to pursue education in Foreign countries. Student / Study Visa
                  is the document that is needed to enter any country legally
                  for educational purpose. Study/ Student Visa can only ba
                  applied after the application or admission Process is
                  completed from the desired College / university, this visa has
                  a permitted time as the course selected by the candidate (let
                  it be any Graduation/ Diploma/ Doctorate). Tenure of this visa
                  is dependent on how long the selected program would be. To
                  know more about Student Visa for each and every country
                  contact our Executive or fill the details and Requirements in
                  the given below query form.
                </p>
                <h2 className="sub-heading">Tourist Visa</h2>
                <p className="para">
                  Tourist visa as the name refers, these types of visas are
                  issued exclusively for tourism. Tourist visa is a pass for an
                  individual to enter a foreign country for the purpose of
                  leisure and tourism. This visa has certain restrictions such
                  as short period of time, Maximum of 30 days. Tourist visas
                  cannot be used for working purpose or Educational purpose.
                  Multiple entry visas are the part of the tourist visa that is
                  used for connecting cities in between the travel. To know more
                  about Tourist Visa for each and every country contact our
                  Executive or fill the details and Requirements in the given
                  below query form.
                </p>
                <h2 className="sub-heading">Work Permit Visa</h2>
                <p className="para">
                  Work permit visa allows business people, permanent workers,
                  temporary workers and others to work in any foreign country.
                  Applicants should have return to their home country after the
                  end of their employment. Open work permit and an
                  employer-specific work permit. To know more about Work permit
                  Visa for each and every country contact our Executive or fill
                  the details and Requirements in the given below query form.
                </p>
              </div>
            </div>
          </div>
          {/* <!-- Student Visa End  --> */}
        </div>
        {/* Ticket */}
        <div
          className={`tab-pane fade${ourpackage === "ticket" ? "show active":""} `}
          id="nav-ticket"
          role="tabpanel"
          aria-labelledby="nav-ticket-tab"
        >
          <div className="service-sec">
            <div className="service-box container">
              <div className="row">
                <div className="col-md-6">
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src="https://img.freepik.com/free-vector/vintage-golden-cinema-tickets-movie-pass-template_1017-23457.jpg?w=996&t=st=1688707984~exp=1688708584~hmac=9755405ee047924daadd6b6b5adf2d78a911f190006d47ad39d53785427ed5d2"
                      alt="Tickets"
                      title="Tickets"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="cont-box">
                    <h2 className="sub-heading">Why Book With Us?</h2>
                    <p className="para">
                      Our Specialized Executives are efficient in selecting the
                      peculiar shortest route available and will also guide in
                      selecting the comfortable means of transport.Our agents
                      will update the availability and the cheapest fare
                      available. Benefits of booking with us
                    </p>
                    <ul>
                      <li>We will Provide economical Tickets</li>
                      <li>24/7 on call assistance</li>
                      <li>Time Saving</li>
                      <li>No Hidden Fees</li>
                      <li>Hassle Free Process</li>
                      <li>After purchase Guidence and services</li>
                      <li>Group Booking Facility</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <h5 className="sub-heading">Our Ticketing Services</h5>
                <p className="para">
                  Executives in Aspire Holidays are well aware of the ticketing
                  strategies so that our customer can obtain cheapest tickets in
                  the below services
                </p>
                <ul>
                  <li>Bus Tickets</li>
                  <li>Train Tickets - Regular & Tatkal</li>
                  <li>Flight Tickets</li>
                  <li>Cruise Tickets</li>
                </ul>
                <h6 className="sub-heading">Finding Your Cheap Tickets</h6>
                <p className="para">
                  Travelling is an important human activity in day to day life.
                  There are many means of transportations in which people travel
                  according to their needs. Our executives are well aware to
                  find the best deals and offers from the Airline or Bus
                  Companies, Incase of Trains we book the emergency Tatkal
                  Tickets as per the customer Enquiry.
                </p>
                <p className="para">
                  Our Executives answers as per the customer queries and plan
                  accordingly and find you the cheapest tickets. One call on the
                  go is only needed for booking your tickets.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Hotel */}
        <div
        className={`tab-pane fade${ourpackage === "hotel" ? "show active":""} `}
          id="nav-hotel"
          role="tabpanel"
          aria-labelledby="nav-hotel-tab"
        >
          <div className="service-box container">
            <div className="row">
              <div className="col-md-6">
                <div className="cont-box">
                  <h2 className="sub-heading">
                    Your Gateway to Unforgettable Hotel Stays
                  </h2>
                  <p className="para">
                    At Aspire Holidays Travel, we understand that where you stay
                    can make or break your travel experience. That's why we're
                    dedicated to providing you with exceptional hotel services
                    that elevate your journey. With us, you're not just booking
                    a room; you're creating memories in comfort and style.
                  </p>
                  <ol>
                    <li>
                      <b>Hotel Booking:</b> Aspire Holidays assists its
                      customers in finding and booking suitable accommodations
                      for their travel needs. They typically have access to a
                      wide range of hotels, resorts, and other types of lodging
                      options worldwide.
                    </li>
                    <li>
                      <b>Accommodation Recommendations:</b>The company's travel
                      experts can recommend hotels based on the customer's
                      preferences, budget, and the purpose of their trip.
                      Whether it's a luxury hotel, a budget-friendly option, a
                      family-friendly resort, or a boutique inn, they help
                      customers make informed decisions.
                    </li>
                    <li>
                      <b>Competitive Pricing:</b>Aspire Holidays aims to provide
                      competitive pricing for hotel bookings. They may have
                      partnerships with various hotel chains and negotiate deals
                      to offer their customers affordable rates.
                    </li>
                    <li>
                      <b>Online Booking Platform:</b>Aspire Holidays may have an
                      online booking platform where customers can search for
                      hotels, view photos and reviews, and make reservations
                      conveniently from their website or mobile app.
                    </li>
                    <li>
                      <b>Special Deals and Packages: </b>The company may offer
                      special deals, promotions, and vacation packages that
                      combine hotel stays with other travel services such as
                      flights, transportation, tours, and activities. These
                      packages often provide added value and convenience for
                      travelers.
                    </li>
                    <li>
                      <b>Personalized Service:</b>Aspire Holidays may strive to
                      provide personalized service, tailoring hotel
                      recommendations and travel arrangements to meet the unique
                      preferences and requirements of each customer.
                    </li>
                  </ol>
                  <p className="para">
                    Remember that the specific services and features offered by
                    Aspire Holidays can vary, and it's essential to check with
                    the company directly or visit their website for the most
                    up-to-date information on their hotel services and
                    offerings.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src="https://img.freepik.com/free-vector/hotel-building-concept-illustration_114360-14039.jpg?w=740&t=st=1694755998~exp=1694756598~hmac=455ae794d94af5cf6da4e679f3e80eb9facd36b757662b8fb794c3aaae8883cc"
                    alt="Hotel"
                    title="Hotel"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Insurance */}
        <div
        className={`tab-pane fade${ourpackage === "insurance" ? "show active":""} `}
          id="nav-insurance"
          role="tabpanel"
          aria-labelledby="nav-insurance-tab"
        >
          <div className="service-box container">
            <div className="row">
              <div className="col-md-6">
                <div className="cont-box">
                  <h2 className="sub-heading">Secure Your Journey With Us</h2>
                  <p className="para">
                    Traveling is about experiencing the world, but it's also
                    essential to be prepared for the unexpected. At Aspire
                    Holidays, we understand the importance of peace of mind when
                    you're away from home. That's why we offer comprehensive
                    travel insurance services to safeguard your adventures.
                  </p>
                  <ol>
                    <li>
                      <b>Comprehensive Coverage: </b> Our travel insurance plans
                      are designed to cover a wide range of situations. From
                      trip cancellations and delays to medical emergencies and
                      lost luggage, we've got you covered.
                    </li>
                    <li>
                      <b>Tailored to Your Needs:</b>We offer insurance plans
                      that can be customized to your specific trip requirements.
                      Whether you're planning a short getaway or a long-haul
                      journey, we have options to suit your needs.
                    </li>
                    <li>
                      <b>Emergency Assistance:</b>Travel with confidence knowing
                      that our 24/7 emergency assistance team is just a phone
                      call away. They are ready to assist you with medical
                      emergencies, travel disruptions, and more.
                    </li>
                    <li>
                      <b>Competitive Rates:</b>We believe that travel insurance
                      should be affordable. Our rates are competitive, ensuring
                      that you get the coverage you need without breaking the
                      bank
                    </li>
                    <li>
                      <b>Easy Online Purchase:</b>Buying travel insurance with
                      Aspire Holidays is simple and convenient. You can purchase
                      your policy online, and our user-friendly interface makes
                      it easy to understand your coverage.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="col-md-6">
                <div className="img-box">
                  <img
                    className="img-fluid"
                    src="https://img.freepik.com/free-vector/travel-insurance-concept-illustration_114360-9937.jpg?w=740&t=st=1694756562~exp=1694757162~hmac=e316629707bfb9c9bf46759e07658c72b15ee69d1b5c9fe871bcaf5ed602406c"
                    alt="Insurance"
                    title="Insurance"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Currency */}
        <div
         className={`tab-pane fade${ourpackage === "currency" ? "show active":""} `}
          id="nav-currency"
          role="tabpanel"
          aria-labelledby="nav-currency-tab"
        >
          {/* <!-- Travel Insurance  --> */}
          <div className="service-sec" id="ce-box">
            <div className="service-box container">
              <div className="row">
                <div className="col-md-6">
                  <div className="cont-box">
                    <h2 className="sub-heading">Currency Exchange</h2>
                    <p className="para">
                      Tourist visa as the name refers, these types of visas are
                      issued exclusively for tourism. Tourist visa is a pass for
                      an individual to enter a foreign country for the purpose
                      of leisure and tourism. This visa has certain restrictions
                      such as short period of time, Maximum of 30 days. Tourist
                      visas cannot be used for working purpose or Educational
                      purpose. Multiple entry visas are the part of the tourist
                      visa that is used for connecting cities in between the
                      travel. To know more about Tourist Visa for each and every
                      country contact our Executive or fill the details and
                      Requirements in the given below query form.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src="https://img.freepik.com/free-vector/illustration-characters-exchanging-currency_53876-37251.jpg?w=740&t=st=1688708437~exp=1688709037~hmac=7c271d7780c6708b252e0c66887c2646b3d5b864f766fd0b0fc5070e9a02e1cd"
                      alt="Currency"
                      title="Currency"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Travel Insurance End  --> */}
        </div>

      </div>

      <Footer setOurpackage={setOurpackage}/>
    </>
  );
};

export default MainService;
