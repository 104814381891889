import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../config";


const Trend = () => {
  const [tdata, setTdata] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const res = await axios.get(config.apiUrl + "home.php");
        setTdata(res.data.tdata);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const options = {
    items: 3,
    loop: true,
    margin: 10,
    nav: false, // Hide navigation arrows
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
      },
      800: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };
  return (
    <>
      <section className="trend">
        <div className="container">
          <h1 className="cursive-heading trend-title">
            Trending <a href="/international-tour-packages">Domestic &International Tour Packages</a>
          </h1>
          <div className="row">
            {
              !loading &&
              
              <OwlCarousel className="owl-theme" loop margin={10} {...options}>
                {tdata.sort(() => Math.random() - 0.5).map((tdata) => (
                  <>
                    <div className="trending-packageitem border-0 " key={tdata.id}>
                     <Link to={`/package-details/${tdata.id}/${tdata.name?.trim().replace(/\s/g, '-').toLowerCase()}`}>

                        <img
                        className="card-img-top"
                          alt="Tour Packages"
                          title="Tour Packages"
                          src={`/uploads/${tdata.img1}`}
                          
                        />
                         </Link>
                      <div className="card-body Bac-white">
                        <div className="tit-box container">
                          <h5 className="mini-heading">
                            {tdata.name}
                          </h5>
                        </div>
                        <hr />

                        <h6 className="container">Inclusions</h6>
                        <div className="in-inner d-flex align-items-center container">
                          {/* <?php if ($row['visa'] === 1) { ?> */}
                          {
                          tdata.visa === 1 &&
                          <Link to={`/package-details/${tdata.id}/${tdata.name?.trim().replace("/","-").replace(/\s/g, '-').toLowerCase()}`}>
                            <img
                              className="img-cover icons-img"
                              src="asset/icons/visa.png"
                              alt="visa"
                              title="visa"
                            />
                            
                          </Link>
                          }

                          
                          {
                              tdata.passport ===1 &&
                          <Link to={`/package-details/${tdata.id}/${tdata.name?.trim().replace("/","-").replace(/\s/g, '-').toLowerCase()}`}>
                            <img
                              className="img-cover icons-img"
                              src="asset/icons/pass.png"
                              alt="passport"
                              title="passport"
                            />
                            
                          </Link>
                          }

                          
                          {
                              tdata.ticket === 1 &&
                              <Link to={`/package-details/${tdata.id}/${tdata.name?.trim().replace("/","-").replace(/\s/g, '-').toLowerCase()}`}>
                              <img
                                  className="img-cover icons-img"
                                  src="asset/icons/ticket.png"
                                  alt="ticket"
                                  title="ticket"
                              />
                             
                              </Link>
                          }

                          
                          {
                              tdata.transport === 1 &&
                              <Link to={`/package-details/${tdata.id}/${tdata.name?.trim().replace("/","-").replace(/\s/g, '-').toLowerCase()}`}>
                              <img
                                  className="img-cover icons-img"
                                  src="asset/icons/trans.png"
                                  alt="transport"
                                  title="transport"
                              />
                             
                              </Link>
                          }

                          
                          {
                              tdata.hotel === 1 &&
                              <Link to={`/package-details/${tdata.id}/${tdata.name?.trim().replace("/","-").replace(/\s/g, '-').toLowerCase()}`}>
                              <img
                                  className="img-cover icons-img"
                                  src="asset/icons/hotel.png"
                                  alt="hotel"
                                  title="hotel"
                              />
                              
                              </Link>
                          }
                        </div>
                        <hr />
                        <div className="row container">
                          <div className="col-6">
                            <Link to={`/package-details/${tdata.id}/${tdata.name?.trim().replace("/","-").replace(/\s/g, '-').toLowerCase()}`}>
                              <button className="btns">Details</button>
                            </Link>
                          </div>
                          <div className="col-6 trend-price">
                            From
                            <br />
                            <span className="mini-heading">
                              {" "}
                              ₹{tdata.amount}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </OwlCarousel>
            }
           

            
          </div>
        </div>
      </section>
    </>
  );
};

export default Trend;
