import { Link } from "react-router-dom";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import CommonHelmet from "./Components/CommonHelmet";

import config from "./config";



const About = () => {
  const options = {
    items: 1,
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    responsive: {
      0:{
        items:1,
      },

      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  const [blogs, setBlogs] = useState([]);
  const [date, setDate] = useState("");
  useEffect(() => {
    axios.get(config.apiUrl + "home.php").then((res) => {
      setDate(res.data.formattedDate);
      setBlogs(res.data.blogs);
      
    });
  }, []);

  return (
    <>
    <CommonHelmet 
      pageTitle="Best Thailand Package From Coimbatore|Book Thailand Tour Packages"
      pageDescription="Explore the world with best Thailand Tour packages in Coimbatore, offering
      International Tour Packages and Domestic Tour Packages.
      "
      pageKeywords="Best International Packages from Coimbatore, Best Domestic Package from
      Coimbatore, Bali Package from Coimbatore,Thailand Package from Coimbatore, Maldives Package from
      Coimbatore
      "
    />
    <Header />
    {/* about banner */}
      <section className="cont-banner">
        <div className="banner-box">
          <h2 className="main-heading">ABOUT US</h2>
          <p className="mini-heading">Get in Touch</p>
        </div>
      </section>

    {/* main section */}
      <section className="main-sec">
        <div className="container">
          <div className="main-box">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="main-cont-box">
                  <p className="cursive-heading">Explore the world with us, one adventure at a time.</p>
                  <h5 className="main-heading">
                    Planning your <span className="animate-text anima">Vacation</span> Trip but not sure where to start?
                  </h5>
                  <p className="para">
                    Aspire Holidays India Pvt Ltd, offers a wide range of services to its clients, including flight & hotel bookings, car rentals, cruise packages & tour packages, visa & passport assistance, corporate travel, MICE (Meetings Incentives Conferences and Exhibition) services, insurance, and much more.
                  </p>
                  <p className="para">Since 2011, Aspire Holidays, recognized as the <b>Best Travel Agency in Coimbatore</b>, has
delighted 3000+ satisfied customers with distinctive holiday tour packages. Experience
some of the best travel destinations in the world with us – we have a range of
<b> Domestic and International packages</b> to suit every budget.
</p>
                  <p className="para">Whether you’re looking for a romantic getaway or an adventure to a new destination, Aspire holidays can help you plan the perfect trip. Our agents are passionate about travel and have an extensive knowledge of the industry, so you can be assured that you will be taken care of. </p>
                  <b>"Unlock the World with Aspire Holidays!"</b>
                  <div className="abt-data mb-4">
                    <p className="para">International Travel Guides</p>
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <br />
                    <p className="para">Domestic Travel Guides</p>
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{ width: '90%' }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="90"></div>
                    </div>
                  </div>
                  <Link to="/package"><button className="btns">Explore More</button></Link>

                </div>
              </div>
              <div className="col-md-6">
                <div className="main-img-box">
                  <img className="img-1 img-fluid" src="asset/images/others/Image Grp3.webp" alt="best travel agency" title="vacation" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="about-service">
          <div className="container">
            <div className="about-top-sec">
              <h6 className="cursive-heading">Let us help you plan your next adventure</h6>
              <div className="row">
                <div className="col-md-6">
                  <h5 className="main-heading">
                    Perfect <span className="animate-text">Vacation</span> {/* <br /> */} come true
                  </h5>
                </div>
                <div className="col-md-6">
                  <p className="para">
                    With a passion for providing exceptional services, we offer a comprehensive range of travel solutions to make your journey memorable and stress-free.
                  </p>
                </div>
              </div>
              <div className="row text-center">
          <div className="col-md-4">
            <div class="service-sec">
            <div class="ser-box">
            <Link to={"/service/passport"}  >
                <img class="img-fluid" src="asset/images/others/passport-icon.webp" alt="Passport" title="Passport"/>
            </Link>
            </div>
              <h6 class="mini-heading">Passport</h6>
              <p class="para">
                Empowering Your Dreams to Soar Beyond Borders! Your Passport to Possibilities - Seamlessly delivered with expertise and care. From application to adventure, we've got you covered.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service-sec">
            <div class="ser-box">
            <Link to={"/service/hotel"}   >
                <img class="img-fluid" src="asset/images/others/Hotel.webp" alt="Hotel" title="Hotel"/>
            </Link></div>
              <h6 class="mini-heading">Hotel</h6>
              <p class="para">
                Your Home Away from Home Awaits! Creating Memories One Stay at a Time - Relax, we'll take care of the rest. Unwind in style and luxury, because you deserve the finest.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service-sec">
                <div class="ser-box">
                <Link to={"/service/visa"}   >
                    <img class="img-fluid" src="asset/images/others/visa-1.webp" alt="Visa" title="Visa"/>
                </Link></div>
            <h6 class="mini-heading">Visa</h6>
              <p class="para">
                Unlocking World Wonders, One Visa at a Time - Your Gateway to Global Adventures! Seamlessly navigating visas so you can explore with ease, because borders shouldn't hold you back.
              </p>
            </div>
          </div>
      </div>
        <div class="row text-center">
          <div class="col-md-4">
            <div class="service-sec">
                <div class="ser-box">
                <Link to={"/service/insurance"}  >
              <img class="img-fluid" src="asset/images/others/insurance-icon.webp" alt="Travel Insurance" title="Travel Insurance"/>
              </Link></div>
              <h6 class="mini-heading">Travel Insurance</h6>
              <p class="para">
                Embark on worry-free adventures, protected by our Travel Insurance Guardians! Your Safety Net Across the Globe - Explore with confidence.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service-sec">
                <div class="ser-box">
                <Link to={"/service/ticket"}   >
              <img class="img-fluid" src="asset/images/others/Ticket .webp" alt="Ticket" title="Ticket"/>
            </Link></div>
              <h6 class="mini-heading">Ticket</h6>
              <p class="para">
                Your Passport to Seamless Journeys - Where Every Destination Begins! Elevating Travel, One Ticket at a Time - We pave the way, you enjoy the ride. 
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service-sec">
                <div class="ser-box">
                <Link to={"/service/currency"}  >
                    <img class="img-fluid" src="asset/images/others/currency-exchange-removebg-preview.webp" alt="Currency Exchange" title="Currency Exchange" />
                    </Link>
                </div>
              <h6 class="mini-heading">Currency Exchange</h6>
              <p class="para">
                Travel the world with ease knowing that your currency exchange is taken care of. We're the currency exchange experts.
              </p>
            </div>
          </div>
        </div>
            </div>
            <div className="about-book">
              <div className="row align-items-center">
                <div className="col-md-3 col-sm-12 mb-md-0 mb-sm-4 test">
                  <div className="ran">
                    <img className="" height="300px" src="asset/images/others/bali1.webp" alt="dream vacation" title="dream vacation" />
                  </div>
                </div>
                <div className="col-md-9 col-sm-12 book-sec">
                  <div className="row p-sm-3 align-items-center">
                    <div className="col-md-12 col-lg-8 book-sec">
                      <p>Exclusive travel deals</p>
                      <p className="sub-heading">Book your dream <span>vacation</span> {/* <br /> */} today!</p>
                    </div>
                    <div className="col-md-12 col-lg-4">
                      <Link to="/travel-agency"><button className="btns">Contact Us</button></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ... Rest of your code ... */}

            <div class="about-testimonial">
          <p class="cursive-heading">Happy Customers Shared Their Experience</p>
          <h6 class="main-heading">Stories from <span>Satisfied</span> Customers</h6>
        </div>
        <div class="row">
  
          <div class="col-md-6">
            <div class="abouttesti">
                <img class="img-fluid" src="asset/images/others/customer.webp" alt="best vacation" title="Customers"/>
            </div>
          </div>
          <div class="col-md-6">
            <div class="testimonial-con">
              {/* <div class="owl-carousel about-test-owl owl-theme"> */}
              <OwlCarousel {...options}>
                <div class="item test-owl">
                  <p>Our All India Tour with aspire holidays was an awesome. We enjoyed to the core. Special thanks to Kandipan the Tour Monitor for arranging the facilities  for us and took care of our safety very much.The journey is safe and secure too..Thanks Aspire holidays 
                  </p>
                  <div class="row align-items-center">
                    <div class="col-3">
                      <div class="imgbox">
                        <img class="" src="asset/images/others/girl1.webp" alt="Feedback" title="Feedback"/>
                      </div>
                    </div>
                    <div class="col-9">
                      <div class="testi-author">
                        <p class="mini-heading name-author">Gladish Sneka E</p>
                        <p class="para">Kerala</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item test-owl">
                  <p>Our trip towards Wayanad was very awesome and we are very happy about Ms.Vinodha from Aspire Holidays , who had taken care of us till our Wayanad journey ends . Car Driver arranged by Aspire Holidays for our trip is very gentle and polite.</p>
                  <div class="row align-items-center">
                    <div class="col-3">
                      <div class="imgbox">
                        <img class="" src="asset/images/others/girl1.webp" alt="Feedback" title="Feedback"/>
                      </div>
                    </div>
                    <div class="col-9">
                      <div class="testi-author">
                        <p class="mini-heading">Hari Baabu V</p>
                        <p class="para">Banglore</p>
                      </div>
                    </div>
                  </div>
                </div>
              {/* </div> */}  </OwlCarousel>
            </div>
          </div>
          
          <div class="testimonial-imgallery">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="abouttesti">
                    <img class="img-fluid" src="asset/images/others/bali2.webp" alt="Satisfied Customers" title="Satisfied Customers"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="testbox">
                    <div class="row">
                      <div class="col-md-6">
                        <img class="img-fluid" src="asset/images/others/bali3.webp" alt="Satisfied Customers" title="Satisfied Customers"/>
                      </div>
                      <div class="col-md-6">
                        <img class="img-fluid" src="asset/images/others/bali4.webp" alt="best travel agency" title="best travel agency"/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <img class="img-fluid" src="asset/images/others/kerala.webp" alt="best Tour Agency" title="best Tour Agency"/>
                      </div>
                      <div class="col-md-6">
                        <img class="img-fluid" src="asset/images/others/customer1.webp" alt="Satisfied Customers" title="Satisfied Customers"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
          </div>
        </div>
      </section>

  <section className="abt-blog-sec">
    <div className="container">
      <div className="abt-blog-head text-center">
        <p className="cursive-heading">True Roaming Tales</p>
        <p className="main-heading mb-5">
          Latest Useful News
        </p>
      </div>
      <div className="blog-row-1">
        <div className="row">
            {/* <?php foreach($blogs as $b) { 
                $dateCreated1 = new DateTime($blog['date_created']);
                $formattedDate1 = $dateCreated1->format('F j, Y');
                $shortContent = substr($b['content'], 0, 50) . '...';
                ?> */}

                {blogs.map((blogs) => (
                 <div className="col-md-6 mb-3">
                 <div className="abt-blog-box">
                   <img className="img-fluid" src={`uploads/blog/${blogs.img}`} alt="best international package" title="best international package" />
                   <div className="cont">
                     <a href={`/blog_details/${blogs.id}/${blogs.name
                            ?.trim()
                            .replace(/\s/g, "-")
                            .toLowerCase()}`} className="date-btn">
                         {/* <?php echo $formattedDate1 ?> */}{date}
                         </a>
                     <p className="mini-heading">
                         {/* <?php echo $b['name'] ?> */}{blogs.name}
                         </p>
                     <p className="para blog-para"   dangerouslySetInnerHTML={{ __html: `${blogs.content.slice(0, 50)}...` }}>
                           {/* <?php echo $shortContent?> */}
                     </p>
                   </div>
                 </div>
               </div>
              ))}


                
         
          {/* <?php } ?> */}
        </div>
      </div>
    </div>
  </section>
  <Footer />
    </>
  );
};

export default About;