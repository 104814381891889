import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "./common/Header";
import Footer from "./common/Footer";
import axios from "axios";
import { useParams } from "react-router";
import { Alert } from "react-bootstrap";
import config from "./config";

import { DatePicker, Space } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


import CommonHelmet from "./Components/CommonHelmet";

const Detailspackage = () => {
  const { id } = useParams();
  const [date, setDate] = useState([]);
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(true);
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          config.apiUrl + `packagedetail.php?id=${id}`
        );
        const res1 = await axios.get(config.apiUrl + "countrystate.php");
        setCountryData(res1.data.data);
        setFilter(res.data.package);
        setDate(res.data.days);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const packages = {
    items: 3,
    loop: true,
    margin: 10,
    nav: false, // Hide navigation arrows
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
    },
  };
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState("");
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  const handleInput = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
const handlePhone = (value, data, event) => {
  setValues((prev) => ({
    ...prev,
    ph: value,
  }));
  };
  const handledate = (date, dateString) => {
    setValues((prev) => ({
      ...prev,
      sd: dateString,
    }));
  };
  const handledate1 = (date, dateString) => {
    setValues((prev) => ({
      ...prev,
      ed: dateString,
    }));
  };

  function alertBox() {
    if (show) {
      return (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          {errors}
        </Alert>
      );
    }
  }

  function msgBox() {
    if (showMsg) {
      return (
        <Alert variant="success" onClose={() => setShowMsg(false)} dismissible>
          {errors}
        </Alert>
      );
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios
        .post(config.apiUrl + "detailpackagemail.php", values)
        .then((res) => {
          if (res.data.success) {
            document.querySelector(".form").reset();
            setErrors(res.data.success);
            setShowMsg(true);
            setValues({});
          } else {
            setErrors("Please Try again Later");
            setShow(true);
          }
        })
        .catch((err) => {
          setErrors("Something Wrong Please Try again Later");
          setShow(true);
        });
    } catch {
      console.log("Error");
    }
  };


  return (
    <>
       <CommonHelmet 
      pageTitle={filter.name}
      pageDescription={filter.mdes}
      pageKeywords={filter.mkey}
    />
      <Header />
      {/* <!-- Package Detail Banner  --> */}
      <div className="pd-banner-sec">
        {/* <div className="owl-carousel pd-banner owl-theme"> */}

        {!loading && (
          <OwlCarousel className="owl-theme" loop margin={10} {...packages}>
            <div className="item">
              <img
                src={`/uploads/${filter.img1}`}
                alt="Package"
                title="Package"
                className="img-fluid"
              />
            </div>
            <div className="item">
              <img
                src={`/uploads/${filter.img2}`}
                alt="Package"
                title="Package"
                className="img-fluid"
              />
            </div>
            <div className="item">
              <img
                src={`/uploads/${filter.img3}`}
                alt="Package"
                title="Package"
                className="img-fluid"
              />
            </div>
          </OwlCarousel>
        )}
        {/* </div> */}
        <div className="pd-header container">
          <div className="row">
            <div className="col-md-3">
              <div className="pd-title-box">
                <h2 className="sub-heading">{filter.name}</h2>
                <p className="para">
                  <span className="fa fa-location-dot"></span> &nbsp;
                  {countryData.map(
                    (country) =>
                      country.value ===
                        filter.country.replace(/\s/g, "").toLowerCase() &&
                      country.name
                  )}
                  {/* {filter.country} */}
                </p>
              </div>
            </div>
            <div className="col-md-9 ico-in">
              <div className="pd-head-cont">
                <div className="row top-1">
                  <div className="col-4">
                    <img
                      className="icons-img"
                      alt="Duration"
                      title="Duration"
                      src="/asset/icons/time.png"
                      height="50px"
                      width="50px"
                    />
                  </div>
                  <div className="col-8 in-tit">
                    <p className="para">Duration</p>
                    <p className="in-title">
                      {filter.tdays}
                      Days
                    </p>
                  </div>
                </div>

                {filter.visa === 1 && (
                  <div className="row top-1">
                    <div className="col-4">
                      <img
                        className="icons-img"
                        alt="Visa"
                        title="Visa"
                        src="/asset/icons/visa.png"
                        height="50px"
                        width="50px"
                      />
                    </div>
                    <div className="col-8 in-tit">
                      <p className="para">Visa</p>
                      <p className="in-title">{filter.visa_title}</p>
                    </div>
                  </div>
                )}

                {filter.passport === 1 && (
                  <div className="row top-1">
                    <div className="col-4">
                      <img
                        className="icons-img"
                        alt="Passport"
                        title="Passport"
                        src="/asset/icons/pass.png"
                        height="50px"
                        width="50px"
                      />
                    </div>
                    <div className="col-8 in-tit">
                      <p className="para">Passport</p>
                      <p className="in-title">{filter.passport_title}</p>
                    </div>
                  </div>
                )}
                {filter.ticket === 1 && (
                  <div className="row top-1">
                    <div className="col-4">
                      <img
                        className="icons-img"
                        alt="Ticket"
                        title="Ticket"
                        src="/asset/icons/ticket.png"
                        height="50px"
                        width="50px"
                      />
                    </div>
                    <div className="col-8 in-tit">
                      <p className="para">Ticket</p>
                      <p className="in-title">{filter.ticket_title}</p>
                    </div>
                  </div>
                )}

                {filter.transport === 1 && (
                  <div className="row top-1">
                    <div className="col-4">
                      <img
                        className="icons-img"
                        alt="Transport"
                        title="Transport"
                        src="/asset/icons/trans.png"
                        height="50px"
                        width="50px"
                      />
                    </div>
                    <div className="col-8 in-tit">
                      <p className="para">Transport</p>
                      <p className="in-title">{filter.transport_title}</p>
                    </div>
                  </div>
                )}
                {filter.hotel === 1 && (
                  <div className="row top-1">
                    <div className="col-4">
                      <img
                        className="icons-img"
                        alt="Hotel"
                        title="Hotel"
                        src="/asset/icons/hotel.png"
                        height="50px"
                        width="50px"
                      />
                    </div>
                    <div className="col-8 in-tit">
                      <p className="para">Hotel</p>
                      <p className="in-title">{filter.hotel_title}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <br />
          <h5 className="text-center">
            <b dangerouslySetInnerHTML={{ __html: filter.quote }}></b>
          </h5>
        </div>
      </div>
      {/* //   <!-- Package Detail Banner End --> */}

      {/* <!-- Package Detail Section  --> */}
      <div className="pd-main-sec position-relative mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="detail-box">
                <div className="highlights">
                  <div className="d-flex align-items-center">
                    <img
                      src="/asset/icons/high.gif"
                      alt="Highlights"
                      title="Highlights"
                      height="50px"
                    />
                    <h5>
                      <b>Highlights</b>
                    </h5>
                  </div>
                  <br />
                  <p
                    dangerouslySetInnerHTML={{ __html: filter.highlights }}
                  ></p>
                </div>
                <p
                  className="para"
                  dangerouslySetInnerHTML={{ __html: filter.content }}
                ></p>
                <hr />
                <h2 className="mini-heading">Itinerary</h2>
                <br />
                <p className="para">
                  Aspire Holidays Team specializes in creating exceptional
                  itineraries and organizing tours for the ultimate travel
                  experience. With this plan, you will be sure to make the most
                  of your time here and create lasting memories.
                </p>
                <br />

                <div className="accordion" id="accordionExample">
                  {/* <?php foreach ($days as $d) { ?> */}
                  {/* // <!--Days Render --> */}
                  {date.map((date) => (
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#id${date.id}`}
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <h5>
                            {date.name} : &nbsp;
                            {date.title}
                          </h5>
                          <br />
                        </button>
                      </h2>
                      <div
                        id={`id${date.id}`}
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p
                            dangerouslySetInnerHTML={{ __html: date.content }}
                          ></p>
                          <br />
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <?php } ?> */}
                </div>
              </div>
              <br />
              <p className="para">
                From start to finish, we Aspire Holidays, have been your travel
                partner, and we'll continue to be there till the last step.
                Until we meet again!
              </p>
              <hr />
            </div> 
           { alertBox() }
          {  msgBox() }
            <div className="col-lg-4 price-col">
              <div className="price-box position-sticky">
                {/* <!--<div className="price-head">-->
        <!--  <div className="txt">-->
        <!--    <p className="para">Price</p>-->
        <!--  <p className="mini-heading">From</p>-->
        <!--  </div>-->
        <!--  <div className="price">-->
        <!--    <p className="sub-heading">₹ <?php echo $package['amount']; ?></p>-->
        <!--  </div>-->
        <!--</div>-->
        <!--<hr>--> */}
                <div className="form-boxx">
                  <p className="mini-heading text-center">
                    Explore the World with Us
                  </p>
                  <form
                    className="form"
                    onSubmit={handleSubmit}
                    method="post"
                    id="myform"
                  >
                    <input
                      className="detail-input"
                      name="name"
                      type="text"
                      placeholder="Type your Name"
                      onChange={handleInput}
                      required
                    />
                    <PhoneInput
                      className="phone-us"
                      country={"in"}
                      onChange={handlePhone}
                      required
                      // value={this.state.phone}
                      // onChange={phone => this.setState({ phone })}
                    />
                    <input
                      className="detail-input"
                      name="email"
                      type="email"
                      placeholder="Insert your Email"
                      onChange={handleInput}
                      required
                    />
                    <input
                      className="detail-input"
                      name="dest"
                      type="text"
                      placeholder="Your Destination"
                      onChange={handleInput}
                      required
                    />
                    <input
                      className="detail-input"
                      name="bp"
                      type="text"
                      placeholder="Type your Boarding point"
                      onChange={handleInput}
                      required
                    />

                    <DatePicker
                      name="sd"
                      placeholder="Date Start"
                      className="textbox-n date-st"
                      type="text"
                      onfocus="(this.type='date')"
                      id="date"
                      onChange={handledate}
                      required
                    />
                    <DatePicker
                      className="textbox-n date-st"
                      name="ed"
                      placeholder="Date End"
                      type="text"
                      onfocus="(this.type='date')"
                      id="date"
                      onChange={handledate1}
                      required
                    />
                    <input
                      className="detail-input"
                      name="no-ad"
                      type="number"
                      placeholder="Adult"
                      onChange={handleInput}
                      required
                    />
                    <input
                      className="detail-input"
                      name="no-ch"
                      type="number"
                      placeholder="Child"
                      onChange={handleInput}
                      required
                    />
                    <input
                      className="detail-input"
                      name="no-sc"
                      type="number"
                      placeholder="Senior"
                      onChange={handleInput}
                      required
                    />
                    <select
                      className="form-selectt"
                      name="occ"
                      onChange={handleInput}
                      required
                    >
                      <option value="Choose Occasion">Choose Occasion</option>
                      <option value="Corporate">Corporate</option>
                      <option value="Family">Family</option>
                      <option value="College">College</option>
                      <option value="Honeymoon">Honeymoon</option>
                      <option value="Adventure">Adventure</option>
                    </select>
                    <select
                      className="form-selectt"
                      name="hot"
                      onChange={handleInput}
                      required
                    >
                      <option value="Choose Hotel Type">
                        Choose Hotel Type
                      </option>
                      <option value="5 Star">5 Star</option>
                      <option value="4 Star">4 Star</option>
                      <option value="3 Star">3 Star</option>
                      <option value="2 Star">2 Star</option>
                      <option value="1 Star">1 Star</option>
                    </select>
                    <select
                      className="form-selectt"
                      name="tra"
                      onChange={handleInput}
                      required
                    >
                      <option value="Choose Travel Mode">
                        Choose Travel Mode
                      </option>
                      <option value="Train">Train</option>
                      <option value="Flight">Flight</option>
                      <option value="Bus">Bus</option>
                      <option value="Cab">Cab</option>
                    </select>

                    <textarea
                      name="message"
                      rows="3"
                      placeholder="Your Message"
                      onChange={handleInput}
                      required
                    ></textarea>
                    <div className="d-grid gap-2">
                      <button type="submit" className="btns cont-btn">
                        Send
                      </button>
                      <br />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Package Detail Section End  --> */}

      <Footer />
    </>
  );
};

export default Detailspackage;
