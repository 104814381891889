import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header";
import Footer from "./common/Footer";
import config from "./config";
import CommonHelmet from "./Components/CommonHelmet";

const Visaa = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const res = await axios.get(config.apiUrl + "visa.php");
        setData(res.data.data);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const filteredData = data.filter((visa) =>
    visa.country.toLowerCase().includes(searchInput.toLowerCase())
  );
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => window.scrollTo(0, 0), [currentPage]);

  const DoubleArrowOne = () => {
    setCurrentPage(1);
  };
  const DoubleArrowTwo = () => {
    setCurrentPage(totalPages);
  };
  const SingleArrowOne = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(1);
    }
  };
  const SingleArrowTwo = () => {
    if (currentPage !== data.length) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(data.length);
    }
  };

  return (
    <>
      <CommonHelmet
        pageTitle="Best International & Domestic Tour package in coimbatore   "
        pageDescription="Discover the Best International & Domestic Tour package in Coimbatore. Explore Thailand, Maldives, Singapore, Malaysia, Dubai, Manali, Kashmir, Bali. "
        pageKeywords="Travel Agents from Coimbatore, Best Travel Agency in Coimbatore, Best International Packages from Coimbatore, Best Domestic Package from Coimbatore, Best America Tour package from coimbatore, Best International & Domestic Tour package in coimbatore "
      />

      <Header />
      {/* Banner Section */}
      <section className="cont-banner">
        <div className="banner-box">
          <h2 className="main-heading">VISA</h2>
          <p className="mini-heading">
            "A journey of a thousand miles begins with a single step"
          </p>
          <div className="visa__search__filter__head">
            <div className="visa__search__filter">
                <button >
                  <svg
                    width="17"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                  >
                    <path
                      d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                      stroke="currentColor"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </button>
                <input
                  className="input__visa"
                  placeholder="Search Visa"
                  required=""
                  type="text"
                  value={searchInput}
                  onChange={handleSearchInputChange}
                />
                <button
                  className="reset__visa"
                  type="reset"
                  onClick={() => setSearchInput("")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Section End */}

      {/* Package Section */}
      <div className="all-package">
        <section className="package-sec visa-packk">
          <div className="container-xxl">
            <div className="row pack-row">
              <div className="col-md-12">
                <div className="visa-box mb-4">
                  <div className="visa-item-box">
                    {
                      !loading &&
                      <div className="row">
                        {filteredData.length > 0 ? (
                          currentItems.map((visa) => (
                            <div key={visa.id} className="col-lg-4 mb-5">
                              <div className="visabx">
                                <div className="visa-item-img">
                                  <Link
                                    className="text-dark"
                                    to={`/visa_package/${visa.id}`}
                                  >
                                    <img
                                      className="img-fluid"
                                      src={`/asset/images/country/${visa.country}.webp`}
                                      alt="Visa package"
                                      title="Visa package"
                                    />
                                  </Link>
                                </div>
                                <div className="visa-con-bx">
                                  <div className="row">
                                    <div className="col-12">
                                      <h5 className="text-center visa__title">
                                        {visa.country}
                                      </h5>
                                      <p className="text-center">
                                        Starting From{" "}
                                        <strong>
                                          <span>₹&nbsp;</span>
                                          {visa.starting_price}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <h3 className="text-center text-warning">
                            No VISA found
                          </h3>
                        )}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Package Section End */}
      <div className="pagesBox"></div>
      {/* <!-- package Section End --> */}
      {/* Footer Component */}
      {/* Assuming setOurpackage is a prop passed to Footer component */}
      {/* You might need to handle this prop properly in your Footer component */}
      {/* <Footer setOurpackage={setOurpackage} /> */}

      {totalPages > 1 && (
        <>
          {currentPage === 1 && (
            <div className="pagesBox">
              <a className="pagination">
                <button className="btns">{currentPage}</button>
              </a>
              <a className="pagination">
                <button
                  className="btns-navy"
                  onClick={SingleArrowTwo}
                  disabled={currentPage === totalPages}
                >
                  &gt;
                </button>
              </a>
              <a className="pagination">
                <button className="btns-navy" onClick={DoubleArrowTwo}>
                  &gt;&gt;
                </button>
              </a>
            </div>
          )}
          {currentPage !== 1 && currentPage !== totalPages && (
            <div className="pagesBox">
              <a className="pagination">
                <button className="btns-navy" onClick={DoubleArrowOne}>
                  {" "}
                  &lt;&lt;
                </button>
              </a>
              <a className="pagination">
                <button
                  className="btns-navy"
                  onClick={SingleArrowOne}
                  disabled={currentPage === 1}
                >
                  &lt;
                </button>
              </a>
              <a className="pagination">
                <button className="btns">{currentPage}</button>
              </a>
              <a className="pagination">
                <button
                  className="btns-navy"
                  onClick={SingleArrowTwo}
                  disabled={currentPage === totalPages}
                >
                  &gt;
                </button>
              </a>
              <a className="pagination">
                <button className="btns-navy" onClick={DoubleArrowTwo}>
                  &gt;&gt;
                </button>
              </a>
            </div>
          )}
          {currentPage === totalPages && (
            <div className="pagesBox">
              <a className="pagination">
                <button className="btns-navy" onClick={DoubleArrowOne}>
                  &lt;&lt;
                </button>
              </a>
              <a className="pagination">
                <button className="btns-navy" onClick={SingleArrowOne}>
                  &lt;
                </button>
              </a>
              <a className="pagination">
                <button className="btns">{totalPages}</button>
              </a>
            </div>
          )}
        </>
      )}

      <Footer />
    </>
  );
};

export default Visaa;
