import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import config from "../config";
import CommonHelmet from "../Components/CommonHelmet";

const MiddleEast = () => {
  const [asia, setAsia] = useState([])
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(config.apiUrl + "east.php");
        setAsia(res.data.country);

        const res1 = await axios.get(config.apiUrl + "countrystate.php");
        setCountryData(res1.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);



  return (
    <>
    <CommonHelmet 
      pageTitle="Best Egypt package |Qatar package from Coimbatore"
      pageDescription="Discover the Best Travel Agents in Coimbatore. Explore enticing Egypt, Qatar, Israel,
      Jordan, and Saudi packages tailored by expert Travel Agents."
      pageKeywords="Travel Agents from Coimbatore, Best Travel Agency in Coimbatore, Best International
      Packages from Coimbatore, Best Domestic Package from Coimbatore, Best Egypt package from
      Coimbatore, Qatar package from Coimbatore, best Israel package from Coimbatore, Best
      Jordan package from Coimbatore, Saudi package from Coimbatore"
    />
      <Header />
      {/* <!-- Banner Section  -->/ */}
      <section className="cont-banner mb-5">
        <div className="banner-box">
          <h2 className="main-heading">MIDDLE EAST</h2>
          <p className="mini-heading">Explore Now</p>
        </div>
      </section>
      {/* <!-- Banner Section End  --> */}

      {/* <!-- Cards  --> */}
      <div className="cnt-sec">
        <div className="container">
          <div className="row">
            {/* <?php
            if (count($country) == 0) {
                echo '<h3 className="text-center text-warning">No packages found</h3>';
            }
            foreach ($country as $row): ?> */}
            {
               !asia?
              <h3 className="text-center text-warning">No packages found</h3>
              :
              <>
              {
                asia.map((asia) => (
                <div className="col-md-6 col-lg-3 p-3 country-col">
                        {countryData.map(
                      (country) =>
                        country.value === asia.country.replace(/\s/g, "").toLowerCase() && (
                          <>
                            <Link to={`/package?sub=6&cat=1&country=${country.value}`}>
                              <div className="cnt-card ">
                                <img
                                  className="country-img"
                                  alt="country"
                                  title="country"
                                  src={`/asset/images/country/${asia.country}.webp`}
                                />
                                <div className="card-namee">
                                  <p className="mini-heading">
                                    {/* {asia.country} */}{country.name}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </>
                        )
                    )}
              </div>
                  
                ))
              }
              </>
            }
           
            {/* <?php endforeach; ?> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MiddleEast;
